/**
 * Hooks, components, and utility functions for handling error page navigation and logging.
 */

import { useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { ApiRequestLogger } from "../logger/apiRequestLogger";
import React from "react";

export const ERROR_PARAM = 'errorMessage';

/* Simple helper to merge a path with query params */
const buildUrl = (path: string, params: URLSearchParams): string => {
    const joinChar = path.includes('?') ? '&' : '?';
    return path + joinChar + params.toString();
}

/**
 * Hook to log an error message from the react router query params. Works in conjunction with useErrorHistoryPush().
 * Usage: Place this hook in error page components to log how the user got to the error page.
 * @param log - An instance of the ApiRequestLogger
 */
export const useErrorLogging = (log: ApiRequestLogger) => {
    const { search } = useLocation();

    const reactRouterParams = new URLSearchParams(search);
    const errorMessage = reactRouterParams.get(ERROR_PARAM);

    useEffect(() => {
        if (errorMessage) {
            log.error(`UI_Error : ${errorMessage}`);
        } else {
            log.warn(`Redirect with missing error message.`)
        }
    }, [errorMessage]);
};

type ErrorHistoryPushFunc = (errorPage: string, message: string) => void;

/**
 * Hook to do history.push to an error page. Works in conjunction with useErrorLogging().
 * Usage: Use the return value function to replace history.push functioncal calls that need to navigate to an error page.
 * @returns A function similar to history.push that also takes an error message for logging.
 */
export const useErrorHistoryPush = (): ErrorHistoryPushFunc => {
    const history = useHistory();
    return (errorPage: string, message: string) => {
        const urlSearchParams = createErrorParams(message);
        const url = buildUrl(errorPage, urlSearchParams);
        history.push(url);
    }
}

/**
 * Create a URLSearchParams object with an error message. Use in combination with <ErrorRedirect />.
 * @param message The error message that will be logged
 * @returns a URLSearchParams object with the error message
 */
export const createErrorParams = (message: string): URLSearchParams => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set(ERROR_PARAM, message);
    return urlSearchParams;
}

type ErrorRedirectProps = {
    to: string;
    message: string;
}

/**
 * An <ErrorRedirect /> component that redirects to an error page with a message. Use in combination with createErrorParams()
 * Usage: Use this component to replace <Redirect /> components that need redirect to an error page.
 */
export const ErrorRedirect: React.FC<ErrorRedirectProps> = (props) => {
    const errorParams = createErrorParams(props.message);
    const url = buildUrl(props.to, errorParams);
    return React.createElement(Redirect, { to: url });
}

