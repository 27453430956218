import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from '@material-ui/core';

const validateStreetAddress = (data: any) => {
    return data !== undefined;
};

export default function streetAddressControl(control: any, errors: any, shrink?: boolean) {
    const {t} = useTranslation();
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="streetAddress"
            rules={{
                required: true,
                validate: validateStreetAddress
            }}
            fullWidth
            id="streetAddress"
            label={(errors.streetAddress?.type === "required" && <div>{t("Street address ")}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.streetAddress?.type === "validate" && <div>{t("Street address ")}<div className="errorLabelAsterisk">*</div></div>) ||
             t("Street address")}
            error={errors.streetAddress?.type === "required" || errors.streetAddress?.type === "validate"}
            helperText=
            {(errors.streetAddress?.type === "required" && t("Street address is required")) ||
            (errors.streetAddress?.type === "validate" && t("Street address is incorrect")) ||
            (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
            autoComplete="street-address"
        />);
}
