import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from '@material-ui/core';

const formatState = (data: any) => {
    data.target.value = data.target.value.replace(/[^A-Za-z]/g, '');
    if(data.target.value.length > 0){
        data.target.value = data.target.value.toUpperCase();
    }
};

export default function stateControl(control: any, errors: any, shrink?: boolean) {
    const {t} = useTranslation();
    return (
        <Controller
            as={<TextField select SelectProps={{
                native: true,
            }}>
                <option value=""></option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
            </TextField>}
            control={control}
            defaultValue=""
            name="state"
            rules={{
                required: true,
            }}
            fullWidth
            id="state"
            label={(errors.state?.type === "required" && <div>{t("State ")}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.state?.type === "validate" && <div>{t("State ")}<div className="errorLabelAsterisk">*</div></div>) ||
             t("State")}
            onInput={(e: React.FormEvent<HTMLDivElement>) => formatState(e) }
            inputProps={{ maxLength: 2 }}
            error={errors.state?.type === "required" || errors.state?.type === "validate"}
            helperText=
            {(errors.state?.type === "required" && t("State is required")) ||
            (errors.state?.type === "validate" && t("State is incorrect")) ||
            (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
        />);
}
