import React from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import UsernameRecoveryPage from '../features/usernameRecovery/UsernameRecoveryPage';
import UsernameRecoveryHandOff from '../features/usernameRecovery/UsernameRecoveryHandOff';
import VerifyContactCustomerService from '../containers/VerifyContactCustomerService';
import AutoInstantActivationLandingPage from '../features/autoinstantactivation/AutoInstantActivationLandingPage';
import AutoInstantActivationErrorPage from '../features/verify/AutoInstantActivationErrorPage';
import { useBrand } from '../../hooks/useBrand';

const UnauthenticatedRouter = () => {
    const brandInfo = useBrand();

    return (
        <Switch>
            <Route exact path="/username-recovery">
                <MemoryRouter>
                    <Switch>
                        <Route exact path="/">
                            <UsernameRecoveryPage brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/hand-off">
                            <UsernameRecoveryHandOff brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/error">
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path="/instantactivation">
                <AutoInstantActivationLandingPage />
            </Route>
            <Route path="/error">
                <AutoInstantActivationErrorPage />
            </Route>
        </Switch>
    );
}

export default UnauthenticatedRouter;
