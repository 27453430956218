import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default function confirmPasswordControl(control: any, errors: any, getValues: any) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const inputProps = {
        endAdornment:
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>,
    };
    const {t} = useTranslation();

    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="confirmPassword"
            rules={{
              required: true,
              validate: value => value === getValues("password")
            }}
            fullWidth
            id="confirmPassword"
            type={showPassword ? "text" : "password"}
            label={t("Confirm password")}
            error={errors.confirmPassword?.type === "required"
            || errors.confirmPassword?.type === "validate"}
            helperText={errors.confirmPassword?.type === "required" && t("Confirming password is required")
            || errors.confirmPassword?.type === "validate" && t("Confirmed password is incorrect")}
            InputProps={inputProps}
            autoComplete="new-password"
    />);
}
