import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function passwordControl(control: any, errors: any, label: string, isNewPassword: boolean, trigger?: any, getValues?: any) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const {t} = useTranslation();
    const inputProps = {
        endAdornment:
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>,
    };

    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="password"
            rules={{
              required: true,
              validate: async () => {
                if (trigger) {
                    if (getValues("confirmPassword")) {
                        await trigger("confirmPassword");
                    }
                }
                return true;
              }
            }}
            fullWidth
            id="password"
            type={showPassword ? "text" : "password"}
            label={label}
            error={errors.password?.type === "required"}
            helperText={errors.password?.type === "required" && t("Password is required")}
            InputProps={inputProps}
            autoComplete={isNewPassword ? "new-password" : "current-password"}
    />);
}
