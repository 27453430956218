import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller} from "react-hook-form";
import {TextField} from "@material-ui/core";

const formatZipCode = (data: any, fullZipCode: boolean) => {

    if (fullZipCode) {
        // Only accept numbers and a hyphen
        if (!data.target.value.match(/^(\d{0,5}|\d{5}-?\d{0,4})$/)) {
            data.target.value = data.target.value.replace(/[^0-9]/g, '');
        }

        // Auto insert '-' only when user continues to type numbers after 5 digits and '-' is not present
        if (data.target.value.length > 5 && data.target.value[5] != "-") {
            data.target.value = data.target.value.substring(0, 5) + "-" + data.target.value.substring(5);
        }
    } else {

        // Only accept numbers and a hyphen
        if (!data.target.value.match(/^(\d{0,5})$/)) {
            data.target.value = data.target.value.replace(/[^0-5]/g, '');
        }
    }

};

export default function zipCodeControl(control: any, errors: any, fullZipCode: boolean, shrink?: boolean) {
    const {t} = useTranslation();
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="zipCode"
            rules={{
                required: true,
                pattern: /^[0-9]{5}$/
            }}
            fullWidth
            id="zipCode"
            label={(errors.zipCode?.type === "required" && <div>{t("Zip code ")}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.zipCode?.type === "pattern" && <div>{t("Zip code ")}<div className="errorLabelAsterisk">*</div></div>) ||
            t("Zip code")}
            onInput={(e: React.FormEvent<HTMLDivElement>) => formatZipCode(e, fullZipCode)}
            inputProps={{maxLength: (fullZipCode ? 10 : 5)}}
            error={errors.zipCode?.type === "required" || errors.zipCode?.type === "pattern"}
            helperText=
                {(errors.zipCode?.type === "required" && t("Zip code is required")) ||
                (errors.zipCode?.type === "pattern" && t("Zip code is incorrect")) ||
                (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
            autoComplete="postal-code"
        />);
}
