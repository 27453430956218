import axios from "axios";

// Used by the PIP React App to make requests to PIP service

export async function isRedirectUrlValid(redirectUrl: string): Promise<boolean> {
    try {
        const result = await axios.post(
            "/api/validateRedirect/", {redirectUrl}, {headers: {'Content-Type': 'application/json'}}
        );
        return result.data;
    } catch (error) {
        return false;
    }
}
