import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { redirectWithSoftLogout } from "../../../util/sessionTimeout";
import { BrandProps, ClientConfigType } from "../../../../types/clientConfigTypes";
import { Grid, Button } from "@material-ui/core";
import TwoButtonContainer from "../../containers/TwoButtonContainer";
import { ClientConfigContext } from "../../../clientContext";
import useDeviceWidth from "../../../hooks/useDeviceWidth";
import { PromotionState } from "../../../../types/reactTypes";
import { useLocation } from "react-router";
import getAccessToken from "../../../util/getAccessToken";
import { appPromotionEvents, sendAppPromotionForAIA } from "../../../services/amplitudeClient";
import { ApiRequestLogger } from "../../../logger/apiRequestLogger";
import { savePromotionStateAndRedirect } from "../../../util/promotionUtils";

export const AppPromotionButtons = ({ brandInfo }: BrandProps) => {
    const location = useLocation<PromotionState>();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const cdnPath = clientConfiguration.cdnPath;
    const { t } = useTranslation();
    const log = new ApiRequestLogger({ eventSource: 'AppPromotionButtons' });

    const { isSmallWidth } = useDeviceWidth();
    const { instance, accounts } = useMsal();

    // Desktop view App Store download button handler
    const redirectToAppStore = () => {
        sendAppPromotionEvent(appPromotionEvents.DOWNLOAD_APP_FROM_PROMOTION);
        savePromotionStateAndRedirect(brandInfo.promotion?.appStoreRedirectLink as string);
    }

    // Desktop view Play Store download button handler
    const redirectToPlayStore = () => {
        sendAppPromotionEvent(appPromotionEvents.DOWNLOAD_APP_FROM_PROMOTION);
        savePromotionStateAndRedirect(brandInfo.promotion?.playStoreRedirectLink as string);
    }

    // Mobile view SKIP button handler
    const handleRedirect = async () => {
        await sendAppPromotionEvent(appPromotionEvents.SKIP_APP_PROMOTION);
        redirectWithSoftLogout(instance, location.state?.redirectUrl);
        
    }

    // Mobile view download app button handler
    const redirectToProperAppSource = () => {
        sendAppPromotionEvent(appPromotionEvents.DOWNLOAD_APP_FROM_PROMOTION);
        savePromotionStateAndRedirect(brandInfo.promotion?.platformStoreRedirectLink as string);
    }

    const sendAppPromotionEvent = async (eventType: string) => {
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
        return sendAppPromotionForAIA(jwt, eventType, brandInfo.brandName ?? brandInfo.brand);
    }

    if (isSmallWidth) {
        return (
            <Grid container item xs={12} className="promotionButtonContainer">
                <TwoButtonContainer
                    firstButtonProps={{ variant: "text", label: t("Skip"), onClick: handleRedirect, brand: brandInfo.brand }}
                    secondButtonProps={{ variant: "contained", type: "button", onClick: redirectToProperAppSource, label: t("Download App"), brand: brandInfo.brand }}
                />
            </Grid>)
    } else {
        return (<>
            <Grid className="promotionWebButtons" container item xs={10} sm={10} direction="column">
                <Grid item xs={6} className="scannerImage">
                    <img src={`${cdnPath}${brandInfo.promotion?.qrCodeImage}`} />
                </Grid>
                <Grid item xs={6}>
                    <Grid item>
                        <Button className="hoverHiddenBtn" onClick={redirectToAppStore}>
                            <img src={`${cdnPath}${brandInfo.promotion?.appStoreImage}`} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button className="hoverHiddenBtn" onClick={redirectToPlayStore}>
                            <img src={`${cdnPath}${brandInfo.promotion?.playStoreImage}`} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
        )
    }


}