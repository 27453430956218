import React from 'react';
import { Grid } from '@material-ui/core';

type LogoProps = {
    imageUri: string,
    altText: string,
    size: number,
}

const Logo = (props: LogoProps) => {
    if (props.imageUri === "/") {
        return (
            <>
                <Grid item xs={12} className="mh39">  
                    &nbsp;
                </Grid>
            </>
        );
    } else {
        return <img src={props.imageUri} alt={props.altText} width={props.size} />;
    }
};

export default Logo;
