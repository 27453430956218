import {SignInPolicy} from "@Praia-Health/spi-azure-client";
import {MyChartConfig} from "@Praia-Health/spi-common-utils";
import {ThemeOptions} from "@material-ui/core";

export type ClientConfigType = {
  customer: string,
  customerSupportNumber: string,
  myChartConfig: MyChartConfig,
  myChartRedirectBaseUrls: MyChartRedirectBaseUrl[],
  brandConfigs: Array<BrandConfig>,
  pimsApiUrl: string,
  b2cTenantConfig: B2CTenantConfigType,
  tier: string,
  cdnPath: string,
  i18nPath: string,
  authorityMetadata?: string,
  mfaConfig: MfaConfig
  version?: string,
  bypassProve: boolean,
  userActivityLimitMs: number,
  amplitudeConfig: AmplitudeConfig
  appPromotion: AppPromotionConfig
}

export type MyChartRedirectBaseUrl = {
  brand: string,
  ehrSystem: string,
  landingPage: string,
  samlSsoPage: string
}

export type B2CTenantConfigType = {
  tenantName: string,
  tenantId: string,
  signInPolicies: Array<SignInPolicy>;
  clientId: string,
  scopes: Array<string>,
  redirectUri: string,
  postLogoutRedirectUri: string
}

export type LegalLinkURLs = {
  faq: string;
  privacyPolicy: string;
  termsAndConditions: string;
  nonDiscrimination: string;
}

export type BrandConfig = {
  backgroundColor?: string;
  backgroundImage?: string;
  brand: string;
  brandName?: string;
  crossRegionEnabled: boolean;
  cssbranding: string;
  default?: boolean;
  links: LegalLinkURLs;
  logoAltText: string;
  logoSize: number;
  logoUri: string;
  promotion?: PromotionConfig;
  signInPolicy?: string;
  theme?: ThemeOptions;
}

/* Brand config specific to the app promotion banner */
export type AppPromotionBrandConfig = {
  logoFilename: string;
  subText: string;
}

/* Brand config specific to the app promotion device (ios or android) */
export type AppPromotionDeviceConfig = {
  device: 'iOS' | 'Android',
  storeLink: string,
  storeImage: string,
  stars: number,
  reviewCount: number
}

export type PromotionConfig = {
  logoImagePath: string
  appImage: string
  brandColor: string
  playStoreImage: string
  appStoreImage: string
  qrCodeImage: string
  platformStoreRedirectLink: string
  playStoreRedirectLink: string
  appStoreRedirectLink: string
  isEnabled: boolean
  redirectUri?: string
  banner?: AppPromotionBrandConfig, // Banner-specific app promo brand config
  apps?: AppPromotionDeviceConfig[]
}

export interface BrandProps {
  brandInfo: BrandConfig;
}

export type ContentSecurityPolicyConfigs = {
    ancestors: string
    defaultSrc: string
    scriptSrc: string
    scriptSrcAttr: string
    connectSrc: string
    styleSrc: string
    imgSrc: string
}

export interface MfaConfig {
  verificationCodeExpireTimeInSeconds: number;
  showResendButtonInMilliSecond: number;
  securityIconUri: string;
  checkMarkIconUri: string;
  mfaEnabled: boolean;
}

export enum MFAVerifyType {
  EmailVerifyType = 'email',
  PhoneVerifyType = 'sms'
}

export type AmplitudeRequest = {
    user_id?: string
    isError?: boolean
    errorType?: string,
    device_id?: string,
    session_id?: number
};

export type AmplitudeConfig = {
  authUrl: string,
  noAuthUrl: string,
  enableEvents: boolean
}

export type AmplitudeIdentifiers = {
  amplitudeDeviceId?: string,
  amplitudeSessionId?: number
}

export enum AmplitudeEventType {
  UsernameRecovery_Confirm = "SSO - Username recovery - Confirm",
  UsernameRecovery_SignIn = "SSO - Username recovery - SignIn",
  UsernameRecoveryScreen = "SSO - Username recovery Screen",
  UsernameRecoveryScreen_Next = "SSO - Username recovery Screen - Next",
  IdentityVerification_ContactService = 'SSO - EHR Authorization - Identity Verification - Contact Service',
  IdentityVerification_ContactService_Call = 'SSO - EHR Authorization - Identity Verification - Contact Service - Call',
  IdentityVerification_ContactService_Close = 'SSO - EHR Authorization - Identity Verification - Contact Service - Close',
  IdentityVerification_NumberVerify_EnterInformation = 'SSO - EHR Authorization - Identity Verification - Number Verified - Enter information', //TODO
  IdentityVerification_DemographicForm = 'SSO - EHR Authorization - Identity Verification - Demographic Form',
  IdentityVerification_DemographicForm_Back = 'SSO - EHR Authorization - Identity Verification - Demographic Form - Back', // TODO:
  IdentityVerification_DemographicForm_Consent = 'SSO - EHR Authorization - Identity Verification - Demographic Form - Consent',
  IdentityVerification_NumberVerified = 'SSO - EHR Authorization - Identity Verification - Number Verified',
  IdentityVerification_PhoneEntry = 'SSO - EHR Authorization - Identity Verification - Phone Entry',
  IdentityVerification_PhoneEntry_Request = 'SSO - EHR Authorization - Identity Verification - Phone Entry - Request',
  IdentityVerification_VerificationCode = 'SSO - EHR Authorization - Identity Verification - Verification Code',
  IdentityVerification_VerificationCode_Back = 'SSO - EHR Authorization - Identity Verification - Verification Code - Back',
  IdentityVerification_VerificationCode_Invalid = 'SSO - EHR Authorization - Identity Verification - Verification Code - Invalid',
  IdentityVerification_RequestNewVerificationCode = 'SSO - EHR Authorization - Identity Verification - Request New Verification Code',
  EHRActivationCode_CodeEntry = "SSO - EHR Authorization - Activation Code - Code Entry",
  EHRActivationCode_CodeEntry_Consent = "SSO - EHR Authorization - EHR Activation Code - Code Entry - Consent",
  EHRActivationCode_CodeEntry_Next = "SSO - EHR Activation Code - Code Entry - Next",
  EHRAuthorized = "SSO - EHR Authorized",
  EHRActivationCode_CodeEntry_Invalid = "SSO - EHR Authorization - EHR Activation Code - Code Entry - Invalid",
  ActivationCode_Prerequisite_Question = "SSO - EHR Authorization - Prerequisite - Question",
  ActivationCode_Prerequisite_Confirm = "SSO - EHR Authorization - Activation Code - Prerequisite - Confirm",
  ActivationCode_Prerequisite_Deny = "SSO - SignUp - EHR Activation Code - Prerequisite - Deny",
  SignIn_AccountType_Success = "SSO - Welcome - Username & Password Screen - SignIn - Success",
  SignIn_AccountType_Failure = "SSO - Welcome - Username & Password Screen - SignIn - Error",
  SSOSignUp_AIA_ActivateAccount_Link = "SSO - SignUp - AIA - Activate Account Link",
  SSOSignUp_AIA_RecordMatch_Info = "SSO - SignUp - AIA - Record Match - Info",
  SSOSignUp_AIA_RecordMatch_Info_Next = "SSO - SignUp - AIA - Record Match - Info - Next",
  SSOSignUp_AIA_RecordMatch_Success = "SSO - SignUp - AIA - Record Match - Success",
  SSOSignUp_AIA_NoRecordMatch_Info = "SSO - SignUp - AIA - No Record Match - Info",
  SSOSignUp_AIA_NoRecordMatch_Info_Next = "SSO - SignUp - AIA - No Record Match - Info - Next",
  SSOSignUp_Screen_AutofillEmail = "SSO - SignUp - Screen - Autofill Email",
  SSOSignUp_Screen_AutofillEmail_Confirm = "SSO - SignUp - Screen - Autofill Email - Confirm",
  SSOSignUp_Screen_AutofillEmail_Update = "SSO - SignUp - Screen - Autofill Email - Update",
  SSOSignUp_Screen = 'SSO - SignUp - Screen',
  SSOSignUp_Cancel = 'SSO - SignUp - Cancel',
  SSOSignUp_Next = 'SSO - SignUp - Next'
}

export type SuccessOrFailureEvent<T extends string> = {
  success: T,
  failure: T
}

export enum AccountStatus {
  BASIC = "Basic",
  EHR_LINKED = "EHRLinked",
  AUTHORIZED = "Authorized",
}

export enum ExternalIdentifierTypes {
  EPI = "EPI",
  AZUREB2C = "azureB2C",
}

export enum AppPromotionMode {
  Banner = "banner",
  FullPage = "fullPage"
}

export type AppPromotionConfig = {
  mode: AppPromotionMode,
  enable: boolean,
  startTime?: string,
  endTime?: string,
  maxDismissed?: number,
  resetDismissed?: string,
  brands: string[],
  devices: string[],
}

