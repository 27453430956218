import React from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import VerifyContactCustomerService from '../containers/VerifyContactCustomerService';
import { useBrand } from '../../hooks/useBrand';
import { MFAVerifyType } from '../../../types/clientConfigTypes';
import DebugMenuPage from '../features/DebugMenuPage';
import ActivationCodePage from '../features/activation/ActivationCodePage';
import AutoInstantActivationAdditionalInfo from '../features/autoinstantactivation/AutoInstantActivationAdditionalInfo';
import ChangePasswordPage from '../features/changePassword/ChangePasswordPage';
import LinkWithDemographics from '../features/demographics/LinkWithDemographics';
import MfaByEmailPage from '../features/mfaSetup/MfaByEmailPage';
import MfaCodeVerificationPage from '../features/mfaSetup/MfaCodeVerificationPage';
import MfaSetupSuccessPage from '../features/mfaSetup/MfaSetupSuccessPage';
import { AppPromotion } from '../features/promotion/AppPromotion';
import RegisterPage from '../features/registration/RegisterPage';
import VerifyCreatingYourAccount from '../features/verify/VerifyCreatingYourAccount';
import CollectDemographics from '../features/verify/demographics/CollectDemographics';
import CollectDemographicsIntro from '../features/verify/demographics/CollectDemographicsIntro';
import CollectPhoneNumberForm from '../features/verify/phone/CollectPhoneNumberForm';
import VerifyPhoneCode from '../features/verify/phone/VerifyPhoneCode';
import { AutoInstantActivationAuthorizationPage } from '../features/autoinstantactivation';

const AuthenticatedRouter = () => {
    const brandInfo = useBrand();

    return (
        <Switch>
            <Route exact path="/">
                <DebugMenuPage />
            </Route>
            <Route exact path="/register">
                <MemoryRouter>
                    <Switch>
                        <Route exact path="/">
                            <RegisterPage />
                        </Route>
                        <Route path="/activation">
                            <ActivationCodePage brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/phone">
                            <CollectPhoneNumberForm brandInfo={brandInfo} header="Verify identity over SMS text message" />
                        </Route>
                        <Route path="/phone-expired">
                            <CollectPhoneNumberForm brandInfo={brandInfo} header="Your activation code is out of date" />
                        </Route>
                        <Route path="/verification-code">
                            <VerifyPhoneCode brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/demographics-intro">
                            <CollectDemographicsIntro brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/demographics">
                            <CollectDemographics brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/creating">
                            <VerifyCreatingYourAccount brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/error">
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path="/autoinstantactivation">
                <MemoryRouter>
                    <Switch>
                        <Route exact path="/">
                            <AutoInstantActivationAuthorizationPage />
                        </Route>
                        <Route path="/promotion">
                            <AppPromotion brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/error">
                            <AutoInstantActivationAdditionalInfo />
                        </Route>
                        <Route path="/contact_support_error">
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path="/demographics">
                <MemoryRouter>
                    <Switch>
                        <Route exact path="/">
                            <LinkWithDemographics brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/error">
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path="/change-password">
                <MemoryRouter>
                    <Switch>
                        <Route exact path="/">
                            <ChangePasswordPage brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/error">
                            <VerifyContactCustomerService />
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
            <Route exact path="/mfa-setup">
                <MemoryRouter>
                    <Switch>
                        <Route exact path="/">
                            <MfaByEmailPage brandInfo={brandInfo}/>
                        </Route>
                        <Route path="/mfa-verification-code">
                            <MfaCodeVerificationPage brandInfo={brandInfo} verifyType={MFAVerifyType.EmailVerifyType}/>
                        </Route>
                        <Route path="/error">
                            <VerifyContactCustomerService />
                        </Route>
                        <Route path="/success">
                            <MfaSetupSuccessPage brandInfo={brandInfo}/>
                        </Route>
                    </Switch>
                </MemoryRouter>
            </Route>
        </Switch>
    );
}

export default AuthenticatedRouter;
