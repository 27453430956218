import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import { Button } from '../../elements/Button';
import UsernameRecoveryForm from './UsernameRecoveryForm';
import { PartialDemographics } from '../../../../types/partialDemographics';
import {getClientCorrelationId, CORRELATION_ID_HEADER} from '../../../CorrelationIDSession';
import {getLogMetadata} from '../../../logger/LogMetadata';
import {BrandProps} from "../../../../types/clientConfigTypes";
import {ApiRequestLogger} from "../../../logger/apiRequestLogger";
import { sendEventByType } from '../../../services/amplitudeClient';
import { AmplitudeEventType } from '../../../../types/clientConfigTypes';

function UsernameRecoveryPage({brandInfo}: BrandProps): JSX.Element {
    const log = new ApiRequestLogger({ eventSource: 'UsernameRecoveryPage' });
    const history = useHistory();
    const { handleSubmit, errors, control, formState } = useForm({ mode: 'onChange' });
    const [loading, setLoading] = useState(false);

    const { isDirty, isValid } = formState;
    const {t, i18n} = useTranslation();

    useEffect(()=>{
        sendEventByType(AmplitudeEventType.UsernameRecoveryScreen)
    },[])
    const onSubmit = async (data: any) => {
        sendEventByType(AmplitudeEventType.UsernameRecoveryScreen_Next);
        setLoading(true);
        const patientPartialDemographics: PartialDemographics = {
            firstName: data.firstName,
            lastName: data.lastName,
            dateOfBirth: data.birthdate,
            zipCode: data.zipCode
        }
        try {
            const body = {
                language: i18n.languages[0],
                brand: brandInfo.brand,
                patientPartialDemographics: patientPartialDemographics
            }
            const response = await fetch(`/api/recoverOrResetCredentials?${getLogMetadata()}`,
                {
                    headers: {
                        "Content-Type": 'application/json',
                        [CORRELATION_ID_HEADER]: getClientCorrelationId(),
                    },
                    method: 'POST',
                    body: JSON.stringify(body)
            });
            if (response.status == 204) {
                history.push("/hand-off");
            } else {
                log.error(`Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                history.push("/error");
            }
        } catch (error) {
            log.error(`Error occurred - ${error}`);
            history.push("/error");
        }
    };

    const renderHeader = () => {
        if (loading) {
            return (
                <>
                    <Typography variant="h2">
                        {t('Finding your account')}
                    </Typography>
                </>
            );
        } else {
            return (
                <>
                    <Typography variant="h2">
                        {t('Enter your information')}
                    </Typography>
                </>
            );
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <>
                    <Grid item xs={12}>&nbsp;</Grid>
                    <Grid container justify="center" item xs={12}>
                        <CircularProgress />
                    </Grid>
                </>
            );
        } else {
            return (
                <UsernameRecoveryForm control={control} errors={errors} />
            );
        }
    };

    const renderActions = () => {
        if (loading) {
            return (
                <>
                </>
            );
        } else {
            return (
                <Grid container justify="center" item xs={12}>
                    <Grid container justify="center" item xs={3}>
                        <Button disabled={!isDirty || !isValid} type="submit" variant="contained">{t('Next')}</Button>
                    </Grid>
                </Grid>
            );
        }
    };

    return(
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <BrandedCard brand={brandInfo} >
                        {{
                            header: (renderHeader()),
                            content: (<>
                                {renderContent()}
                            </>),
                            actions: (<>
                                {renderActions()}
                            </>)
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );
}

export default UsernameRecoveryPage;
