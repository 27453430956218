import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

export default function lastNameControl(control: any, errors: any, shrink?: boolean) {
    const {t} = useTranslation();
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="lastName"
            rules={{
                required: true
            }}
            fullWidth
            id="lastName"
            label={(errors.lastName?.type === "required" && <div>{t("Last name ")}<div className="errorLabelAsterisk">*</div></div>) ||
            t("Last name")}
            error={errors.lastName?.type === "required" || errors.lastName?.type === "validate"}
            helperText=
                {(errors.lastName?.type === "required" && "Required") ||
                (errors.lastName?.type === "validate" && t("Please enter your last name")) ||
                (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
            autoComplete="family-name"
        />);
}
