import {v4 as uuidv4} from 'uuid';
import {OPENID_PARAMS} from "../types/openid_enum";

export const CORRELATION_ID_HEADER = "correlation-id";

// We need to do this because the library 'correlation-id' doesn't work on the browser.
export function setClientCorrelationId(): void {
    let correlationId = window.sessionStorage.getItem(OPENID_PARAMS.CORRELATION_ID);
    if (!correlationId) {
        const searchParams = new URLSearchParams(document.location.search);
        correlationId = searchParams.get(OPENID_PARAMS.CORRELATION_ID);
    }
    correlationId = correlationId || uuidv4();
    window.sessionStorage.setItem(OPENID_PARAMS.CORRELATION_ID, correlationId as string);
}

export function getClientCorrelationId(): string {
    return window.sessionStorage.getItem(OPENID_PARAMS.CORRELATION_ID) as string;
}
