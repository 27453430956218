/**
 * https://openid.net/specs/openid-connect-basic-1_0.html#RequestParameters
 *
 * List of Query Params (Query Params accessed via document.location.search)
 * brand: string. controls the css theming
 * redirect_uri: string. required.
 * incomingMyChartUrl: string. (misnamed) in the unauth'd flow, this is passed to AzureB2C. This should be changed to cancel_redirect_uri.
 * cancel_redirect_uri: string. The url is used to go back to the client's landing page when login/registration failed.
 * system: string. (optional) if specified, the logic checks for authorization in that system. If the consumer has auth, then redirect the end-user back to the redirection endpoint
 * cross_region: bool. (default = false). If true, it will not respect the redirect_uri. It redirects to the MyChart System that it authorized for.
 * mobile: boolean. Used for redirection. See CollectDemographics.tsx
 * instAct: string. Used by instant activation code. See CollectDemographics.tsx. Can be deprecated
 * correlationId: string.
 */
export enum OPENID_PARAMS {
    BRAND = "brand",
    REDIRECT_URI = "redirect_uri",
    REDIRECT_URL = "redirect_url", // deprecated. Used by mobile registration
    CANCEL_REDIRECT_URI = "cancel_redirect_uri",
    INCOMING_MYCHART_URL = "incomingMyChartUrl", // deprecated, use CANCEL_REDIRECT_URI
    EHR_SYSTEM = "ehr_system",
    INSTANCE = "instance",
    SYSTEM = "system", // deprecated. use EHR_SYSTEM
    // Cross Region is for MyChart-SAML flow. No other clients use set cross_region to true
    CROSS_REGION = "cross_region",
    MOBILE = "mobile", // mobile=true is a MyChart specific query parameter. You should use device_type for your use_case.
    INST_ACT = "InstAct", // Used by AIA
    STATE = "state",
    CORRELATION_ID = "correlationId",
    LANG = "lang",
    LOCATION_STATE = 'locationState',
    DISPLAY_MFA_FLOWS = 'display_mfa_flows',
    DEVICE_TYPE = "device_type",
    CDP_DATA_B64 = "cdpDataB64",
    AMPLITUDE_DEVICE_ID = "amp_device_id", //Amplitude device Id generated and passed from Mychart. This param is required to stitch the cross domain amplitude events.
    AMPLITUDE_SESSION_ID = "amp_session_id" //Amplitude session Id generated and passed from Mychart. This param is required to stitch the cross domain amplitude events.
}

/**
 * Specialized parameters from the MyChart SAML workflow
 */
export enum MYCHART_TO_PIP_PARAMS {
    EHR_SYSTEM = "ehr_system",
    EHRSYSTEM = "ehrsystem",            // Deprecated. When the spi_pip_redirect.js removes this reference, this can be deleted
    DEVICE_TYPE = "device_type",        // Either webapp or mobile
    DEVICETYPE = "deviceType",          // Deprecated. When the spi_pip_redirect.js removes this reference, this can be deleted
    PIP_OAUTH_FLOW = "pip_oauth_flow",  // Used to control how we send the spi_msal_token to Gracebot
    PIPOAUTHFLOW = "pipOAuthFlow",      // Deprecated. When the spi_pip_redirect.js removes this reference, this can be deleted
    BRAND = "brand",
    CDPDATAB64 = "cdpDataB64",          // Controlled by Tealium. Not added by SPI.
    POSTLOGINURL = "postloginurl",      // Controlled by MyChart. Not added by SPI.
    FORCE_AUTHN = "forceAuthn",      // Controlled by MyChart. Equivalent to prompt=login.
    IDP = "idp",      // Controlled by MyChart. Not added by SPI.
    AMPLITUDE_DEVICE_ID = "deviceId", //Amplitude device Id generated and passed from Mychart. This param is required to stitch the cross domain amplitude events.
    AMPLITUDE_SESSION_ID = "sessionId" //Amplitude session Id generated and passed from Mychart. This param is required to stitch the cross domain amplitude events.
}

export enum AUTO_INSTANT_ACTIVATION_PARAMS {
    REGISTER_PHONE = "register_phone",  // This query parameter to show the phone validation page in the prove flow (Register).
    INSTANT_ACTIVATION_CODE = "instant_activation_code",
    CANCEL_REDIRECT_URI = "cancel_redirect_uri",
    REDIRECT_URI = "redirect_uri",
    BRAND = "brand",
    EHR_SYSTEM = "ehr_system",
    IS_AIA_LINK_EXPIRED = "is_aia_link_expired",
    IS_AUTHORIZED = "is_authorized",
    EMAIL= "aia_email",
    IS_NON_PATIENT_PROXY = "is_non_patient_proxy"
}
