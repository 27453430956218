import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from '@material-ui/core';

const validateGender = (data: any) => {
    const validationResult =
        data === 'male' || data === 'female' || data === 'other';
    return validationResult;
};

export default function genderControl(control: any, errors: any, shrink?: boolean) {
    const {t} = useTranslation();
    return (

        <Controller
            as={
                <TextField select SelectProps={{
                    native: true,
                }}>
                        <option value="" />
                        <option value="male">{t("Male")}</option>
                        <option value="female">{t("Female")}</option>
                        <option value="other">{t("Other")}</option>
                </TextField>
            }
            control={control}
            defaultValue=""
            name="gender"
            rules={{
                required: true,
                validate: validateGender
            }}
            variant={"standard"}
            fullWidth
            id="gender"
            label={(errors.gender?.type === "required" && <div>{t("Gender ")}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.gender?.type === "validate" && <div>{t("Gender ")}<div className="errorLabelAsterisk">*</div></div>) ||
             t("Gender")}
            error={errors.gender?.type === "required" || errors.gender?.type === "validate"}
            helperText=
            {(errors.gender?.type === "required" && t("Gender is required")) ||
                (errors.gender?.type === "validate" && t("Gender is incorrect")) ||
                (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
            autoComplete="sex"
        />
    );
}
