import React from 'react';
import Grid from '@material-ui/core/Grid';
import dateOfBirthControl from '../../controls/DateOfBirthControl';

export type CollectDemographicsFormProps = {
    control: any,
    errors: any,
    infoComponent: string,
}

function CollectDemographicsForm(props: CollectDemographicsFormProps) {
    return (
        <Grid container spacing={2} direction="row" justify="space-evenly">
            <Grid container justify="center" alignContent="flex-end" alignItems="flex-end">
                <Grid container justify="center">
                    <Grid item xs={10}>
                        {dateOfBirthControl(props.control, props.errors, true)}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CollectDemographicsForm;
