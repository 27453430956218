import { useContext } from 'react';
import { ClientConfigType } from '../../types/clientConfigTypes';
import { ClientConfigContext } from '../clientContext';

const recursiveConfigSearch = <T> (config: any, keyParts: string[]): T | undefined => {
    const [key, ...rest] = keyParts
    if (key in config) {
        const value = config[key];
        if (rest.length === 0) {
            return value;
        } else {
            return recursiveConfigSearch(config[key], rest);
        }
    }
}

const useConfig = <T> (key: string): T | undefined => {
    const clientConfiguration = useContext<ClientConfigType>(ClientConfigContext);
    const keyParts = key.split('.');
    const value = recursiveConfigSearch<T>(clientConfiguration, keyParts);
    if (value === undefined) {
        console.error(`Config key ${key} not found`);
    }
    return value;
}

export default useConfig;
