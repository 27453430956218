import React from 'react';
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom';

import Button from '../elements/Button';

export type TwoButtonContainerProps = {
    firstButtonProps: {
        variant: "outlined" | "contained" | "text",
        disabled?: boolean | undefined,
        label: string,
        link?: string,
        brand?: string,
        onClick?:() => void
    },
    secondButtonProps: {
        variant: "outlined" | "contained" | "text",
        disabled?: boolean | undefined,
        label: string,
        link?: string,
        brand?: string,
        type?: "button" | "submit" | undefined,
        onClick?:() => void
    }
}

function TwoButtonContainer(props: TwoButtonContainerProps) {
    const renderFirstButton = () => {
        if (props.firstButtonProps.link) {
            return (
                <Link className="txtDecorationNone" to={props.firstButtonProps.link}>
                    <Button
                        variant={props.firstButtonProps.variant}
                        type="button"
                    >
                        {props.firstButtonProps.label}
                    </Button>
                </Link>
            );
        } else {
            return (
                <Button
                    variant={props.firstButtonProps.variant}
                    type="button"
                    onClick={props.firstButtonProps.onClick}
                >
                    {props.firstButtonProps.label}
                </Button>
            );
        }
    }

    const renderSecondButton = () => {
        if (props.secondButtonProps.link) {
            return (
                <Link style={{textDecoration: 'none'}} to={props.secondButtonProps.link}>
                    <Button
                        disabled={props.secondButtonProps.disabled}
                        variant={props.secondButtonProps.variant}
                        type="submit"
                    >
                        {props.secondButtonProps.label}
                    </Button>
                </Link>
            );
        } else {
            return (
                <Button
                    disabled={props.secondButtonProps.disabled}
                    variant={props.secondButtonProps.variant}
                    type={props.secondButtonProps.type ?? "submit"}
                    onClick={props.secondButtonProps.onClick}
                >
                    {props.secondButtonProps.label}
                </Button>
            );
        }
    }

    return (
        <Grid container justify="space-around" item xs={12} className="pd0">
            <Grid container justify="center" item xs={3}>
                {renderFirstButton()}
            </Grid>
            <Grid container justify="center" item xs={3}>
                {renderSecondButton()}
            </Grid>
        </Grid>
    );
}

export default TwoButtonContainer;
