import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BrandProps, ClientConfigType } from "../../../../types/clientConfigTypes";
import { Grid, Typography } from "@material-ui/core";
import { AppPromotionButtons } from "./AppPromotionButtons";
import { ClientConfigContext } from "../../../clientContext";

export const AppPromotionControl = ({ brandInfo }: BrandProps) => {
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const cdnPath = clientConfiguration.cdnPath;
    const { t } = useTranslation();

    return (
        <div className="promotionContentContainer">
            <Grid container justify="center" item >
                <Grid item xs={11} sm={10} >
                    <img src={`${cdnPath}${brandInfo.promotion?.logoImagePath}`} alt={brandInfo.logoAltText} className="promotionContentBrandLogo" />
                </Grid>
                <Grid item xs={11} sm={10}>
                    <Typography variant="h2" className="promotionContentHeading" style={{ color: brandInfo.promotion?.brandColor }}>
                        {t('Download')}
                    </Typography>
                </Grid>
                <Grid item xs={11} sm={10}>
                    <Typography variant="h2" className="promotionContentBrandHeading" style={{ color: brandInfo.promotion?.brandColor }}>
                        {t(brandInfo.brandName + ` App`)}
                    </Typography>
                </Grid>
                <Grid item xs={11} sm={10}>
                    <div className="promotionContentTextContainer">
                        <Typography className="promotionContentText">{t('Get everything you need to take charge of your health, wherever you are.')}</Typography>
                        <ul>
                            <li className="mb5">
                                <Typography className="promotionContentText">
                                    {t('Schedule your appointment')}
                                </Typography>
                            </li>
                            <li className="mb5">
                                <Typography className="promotionContentText">
                                    {t('View your lab and test results')}
                                </Typography>
                            </li>
                            <li className="mb5">
                                <Typography className="promotionContentText">
                                    {t('See your provider with a virtual visit')}
                                </Typography>
                            </li>
                            <li className="mb5">
                                <Typography className="promotionContentText">
                                    {t('Request a prescription refill')}
                                </Typography>
                            </li>
                        </ul>
                    </div>

                </Grid>
                <AppPromotionButtons brandInfo={brandInfo} />
            </Grid>
        </div>
    )
}