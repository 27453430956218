import { Grid, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(() => ({
    item: {
        alignItems: "center",
        height: 100,
        justifyContent: "center"
    },
}));

export type BrandedCardHeaderProps = {
    children: ReactNode[] | ReactNode
}

const BrandedCardHeader = (props: BrandedCardHeaderProps) => {
    const classes = useStyles();
    const { children } = props;
    return (
        <Grid item container xs={12} className={classes.item}>
            {children}
        </Grid>
    );
}
BrandedCardHeader.displayName = 'BrandedCardHeader';
export default BrandedCardHeader;
