import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BrandedCard, { BrandedCardActions, BrandedCardContent, BrandedCardHeader } from '../../containers/BrandedCard_v2';
import { Button } from '../../elements/Button';
import {AmplitudeEventType, ClientConfigType} from "../../../../types/clientConfigTypes";
import { AUTO_INSTANT_ACTIVATION_PARAMS, OPENID_PARAMS } from '../../../../types/openid_enum';
import { useMsal } from '@azure/msal-react';
import getAccessToken from '../../../util/getAccessToken';
import { sendEventForAIA } from '../../../services/amplitudeClient';
import {ClientConfigContext} from "../../../clientContext";
import { ApiRequestLogger } from '../../../logger/apiRequestLogger';
import { useErrorLogging } from '../../../util/errorUtils';
import TypographyI18N from '../../elements/TypographyI18N';
import { useBrand } from '../../../hooks/useBrand';
import { buildUrlParams } from '../../../util/urlUtils';
import { useMyChartRedirect } from '../../../hooks/useMyChartRedirect';
import { useOidcState } from '../../../hooks/useOidcState';
import { AIAState } from '../../../../types/aiaState';

function AutoInstantActivationAdditionalInfo(): JSX.Element {
    const {instance, accounts} = useMsal();
    const [jwt, setJwt] = useState("");
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const { brand, crossRegionEnabled } = useBrand();
    const log = new ApiRequestLogger({ eventSource: 'AutoInstantActivationError' });
    const oidcState = useOidcState<AIAState>({ log, isRequired: true });
    const { getSamlSsoPage,  } = useMyChartRedirect();
    const {t} = useTranslation();
    useErrorLogging(log);

    const searchParams = new URLSearchParams(window.location.search);
    const crossRegion = searchParams.get(OPENID_PARAMS.CROSS_REGION);
    const ehrSystem = oidcState?.ehrSystem as string;
    const redirectURI = getSamlSsoPage(ehrSystem);

    const nextUrlParams = buildUrlParams({
        [OPENID_PARAMS.BRAND]: brand,
        [OPENID_PARAMS.CROSS_REGION]: crossRegion ?? (crossRegionEnabled ? 'true' : 'false'),
        [OPENID_PARAMS.EHR_SYSTEM]: ehrSystem,
        [OPENID_PARAMS.REDIRECT_URI]: redirectURI,
        [AUTO_INSTANT_ACTIVATION_PARAMS.REGISTER_PHONE]: 'true'
    });
    const nextUrl = `/register?${nextUrlParams.toString()}`;

    const onNextButtonClick = () =>{
        sendEventForAIA(jwt, AmplitudeEventType.SSOSignUp_AIA_NoRecordMatch_Info_Next);
        window.location.href = nextUrl; // no soft logout since it goes back to PIP
    }

    useEffect(()=>{
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt)=>{
            setJwt(jwt);
            sendEventForAIA(jwt, AmplitudeEventType.SSOSignUp_AIA_NoRecordMatch_Info);
        });
    }, []);

    return (
        <BrandedCard>
            <BrandedCardHeader>
                <TypographyI18N variant='h2'>Provide additional information</TypographyI18N>
            </BrandedCardHeader>
            <BrandedCardContent>
                <TypographyI18N variant='h2'>We couldn’t find a match. Please provide additional information to help us locate your medical record.</TypographyI18N>
            </BrandedCardContent>
            <BrandedCardActions>
                <Button type="submit" variant="contained" onClick={onNextButtonClick}>{t('Next')}</Button>
            </BrandedCardActions>
        </BrandedCard>
    );
}

export default AutoInstantActivationAdditionalInfo;
