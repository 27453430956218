import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField, makeStyles } from '@material-ui/core';
import validator from 'validator';

// Styles for the MUI TextField's FormHelperText. See: https://v4.mui.com/api/form-helper-text/#formhelpertext-api 
const useHelperTextStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        top: 70,
    }
}));

// Styles for the MUI TextField's Input. See: https://v4.mui.com/api/input/
const useInputStyles = makeStyles(() => ({
    root: {
        height: 51,
        marginTop: "0 !important", // shame.  no idea how to not have to do this
        "& input": {
            padding: 16
        }
    }
}));

// Styles for the MUI TextField's Input. See: https://v4.mui.com/api/input-label/
const useLabelStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        position: 'relative',
        top: -28,
        transform: "translate(12px, 20px) scale(1)",
    },
}));

const validateEmail = (data: string): boolean => {
    const validationResult = validator.isEmail(data);
    return validationResult;
};

type EmailControlProps = {
    control: any,
    errors: any,
    defaultValue?: string,
    disabled?: boolean,
    label?: string,
    placeholder?: string,
    showLabel?: boolean,
    variant?: "filled" | "outlined" | "standard"
}

const EmailControl = (props: EmailControlProps) => {
    const {
        control,
        errors,
        defaultValue = "",
        disabled = false,
        label = "Your Email",
        placeholder = "",
        showLabel = true
    } = props;
    const {t} = useTranslation();

    let helperText = '';
    if (errors.email?.type === "required") {
        helperText = t("Email is required");
    } else if (errors.email?.type === "validate") {
        helperText = t("Email is incorrect");
    }

    let variant = props.variant;
    if (!variant) {
        variant = disabled ? "filled" : "standard"
    }
    
    return (
        <Controller
            as={TextField}
            autoComplete="username"
            control={control}
            defaultValue={defaultValue}
            disabled={disabled}
            error={errors.email?.type === "required" || errors.email?.type === "validate"}
            fullWidth
            helperText={helperText}
            id="email"
            label={showLabel ? t(label) : ''}
            name="email"
            rules={{
                required: true,
                validate: validateEmail
            }}
            variant={variant}
            inputProps={{
                placeholder: t(placeholder)
            }}
            FormHelperTextProps={{
                classes: useHelperTextStyles()
            }}
            InputProps={{
                classes: useInputStyles()
            }}
            InputLabelProps={{
                classes: useLabelStyles(),
                disableAnimation: true,
                disabled: false,
                error: false,
                shrink: false
            }}
        />
    );
}

export default EmailControl;
