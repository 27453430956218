import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import EmailControl from '../../controls/EmailControl';
import { Typography } from '@material-ui/core';

export type MfaEmailFormProps = {
    control: any,
    errors: any,
    trigger: any,
    getValues: any
}

function MfaEmailForm(props: MfaEmailFormProps) {
    const { t } = useTranslation();
    return (
        <Grid container justify="center" item xs={12} className="mb-4">
            <Grid item xs={12} className="mb-1">
                <Typography variant="body1" align="left">
                    {t('Enter your email address where the verification code will be sent.')}
                </Typography>
            </Grid>
            <Grid item xs={12} className="minHeight-7">
                <EmailControl control={props.control} errors={props.errors} />
            </Grid>
        </Grid>
    );
}

export default MfaEmailForm;
