import { useContext } from 'react';
import { BrandConfig, ClientConfigType } from '../../types/clientConfigTypes';
import { ClientConfigContext } from '../clientContext';
import { OPENID_PARAMS } from '../../types/openid_enum';
import { OidcState } from '../../types/oidcState';

// Get the brand config from the URL parameters (or from an OIDC state) or fallback on the default brand
const useBrand = (state?: OidcState): BrandConfig => {
    const searchParams = new URLSearchParams(window.location.search);
    const brand = searchParams.get(OPENID_PARAMS.BRAND)?.toLowerCase() ?? state?.brand ?? '';
    const clientConfiguration = useContext<ClientConfigType>(ClientConfigContext);
    const brandConfig = clientConfiguration.brandConfigs.find((configBrand) => configBrand.brand.toLowerCase() === brand.toLowerCase());

    if (brandConfig) {
        return brandConfig;
    }

    return useDefaultBrand();
}

const useDefaultBrand = (): BrandConfig => {
    const clientConfiguration = useContext<ClientConfigType>(ClientConfigContext);

    const defaultBrandConfig = clientConfiguration.brandConfigs.find((brandConf) => brandConf.default);
    if (defaultBrandConfig) {
        return defaultBrandConfig;
    }

    throw new Error('No default brand found in the config.');
}

export { useBrand, useDefaultBrand };
