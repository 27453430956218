import React, {ReactNode} from 'react';
import {Button as MuiButton, Theme, makeStyles} from '@material-ui/core';
import { ButtonStylesType, buttonStyles } from '../../../assets/themes/buttonStyles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export type ButtonProps = {
    children: ReactNode,
    disabled?: boolean | undefined,
    variant: "outlined" | "contained" | "text" | "banner",
    type: "button" | "reset" | "submit" | undefined,
    onClick?: any;
    heightOverride?: string,
    widthOverride?: string,
}

const useButtonStyles = makeStyles<Theme, ButtonStylesType>(buttonStyles);

const defaults: ButtonStylesType = {
    backgroundColor: () => 'initial',
    borderWidth: 1,
    fontSize: 16,
    fontWeight: 500,
    height: 44
}

export const Button = (
    {
        disabled,
        variant,
        type,
        children,
        onClick,
        heightOverride,
        widthOverride
    }: ButtonProps) => {
    
    const buttonStyleProps = {...defaults};
    let muiVariant: "outlined" | "contained" | "text" | undefined;

    switch (variant) {
        case 'banner':
            muiVariant = 'outlined';
            buttonStyleProps.borderWidth = 1;
            buttonStyleProps.fontSize = 12;
            buttonStyleProps.fontWeight = 500;
            buttonStyleProps.height = 39;
            buttonStyleProps.width = 80;
            buttonStyleProps.backgroundColor = (palette: PaletteOptions) => palette.common?.white || 'initial';
            break;
        default:
            muiVariant = variant;
            break;
    }

    const classStyle = useButtonStyles(buttonStyleProps);

    return <MuiButton
        classes={classStyle}
        disabled={disabled}
        variant={muiVariant}
        disableElevation
        type={type}
        onClick={onClick}
        style={{height: heightOverride, width: widthOverride}}
    >
        {children}
    </MuiButton>;
};

export default Button;
