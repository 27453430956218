import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

export default function activationCodeControl(control: any, errors: any) {
    const {t} = useTranslation();
    const formatCode = (data: React.ChangeEvent<HTMLInputElement>) => {
        //make all chars uppercase
        data.target.value = data.target.value.toUpperCase();
        if(data.target.value.length < 6){
            data.target.value = data.target.value.replace(/[^A-Z0-9]/g, '');
        } else if(data.target.value.length == 6 && data.target.value[5] != '-' && data.target.value.indexOf('-') < 0){
            //auto insert '-' only when user continues to type numbers after 5 digits and - is not present
            data.target.value = data.target.value.substring(0,5) + "-" + data.target.value[5];
        } else if (data.target.value.length == 12 && data.target.value[11] != '-' && data.target.value.indexOf('-') == 5){
            //auto insert '-' only when user continues to type numbers after 10 digits and - is not present
            data.target.value = data.target.value.substring(0,11) + "-" + data.target.value[11];
        }
    };
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="activationCode"
            rules={{
                required: true,
                pattern: /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/
            }}
            fullWidth
            id="activationCode"
            label={t("Activation code")}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => formatCode(e) }
            inputProps={{
                maxLength: 17,
            }}
            error={errors.activationCode?.type === "required" || errors.activationCode?.type === "pattern"}
            helperText=
            {(errors.activationCode?.type === "required" && t("Activation code is required")) ||
            (errors.activationCode?.type === "pattern" && t("Activation code is incorrect")) ||
            (true && " ")}
            InputLabelProps={{
                error: false,
            }}
        />);
}
