import React from 'react';
import Grid from '@material-ui/core/Grid';
import passwordControl from "../../controls/PasswordControl";
import confirmPasswordControl from "../../controls/ConfirmPasswordControl";
import { useTranslation } from 'react-i18next';
import existingPasswordControl from "../../controls/ExistingPasswordControl";

export type ChangePasswordFormProps = {
    control: any,
    errors: any,
    getValues: any,
    trigger: any,
}

function ChangePasswordForm(props: ChangePasswordFormProps): JSX.Element {
    const {t} = useTranslation();
    return (
        <Grid container spacing={9}>
            <Grid container justify="center" item xs={12}>
                <Grid item xs={11}>&nbsp;
                </Grid>
                <Grid item xs={11}>
                    {existingPasswordControl(props.control, props.errors, t("Enter your current password"))}
                </Grid>
                <Grid item xs={11}>&nbsp;
                </Grid>
                <Grid item xs={11}>
                    {passwordControl(props.control, props.errors, t("New password"),true, props.trigger, props.getValues)}
                </Grid>
                <Grid item xs={11}>&nbsp;
                </Grid>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={11} className="noteText">{`${t("8-16 characters, containing 3 out of 4 of the following")}: ${t("Lowercase characters, uppercase characters, digits (0-9), and one or more of the following symbols")}: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / \` ~ " ( ) ;`}</Grid>
                </Grid>
                <Grid item xs={11}>&nbsp;
                </Grid>
                <Grid item xs={11}>
                    {confirmPasswordControl(props.control, props.errors, props.getValues)}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ChangePasswordForm;
