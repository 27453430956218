// Sends logs via api request
import {getClientCorrelationId} from "../CorrelationIDSession";
import {ClientLogger} from "@Praia-Health/spi-common-utils/dist/logging/clientLogger";
import {LoggerContext} from "@Praia-Health/spi-common-utils/dist/types/clientLoggerTypes";

export class ApiRequestLogger extends ClientLogger {

    constructor(context?: LoggerContext) {
        super({
            context: context || {}
        });
        this.addContext({
            '@app_name': 'SPI.PatientIdentityPortal',
            '@type': 'log',
            pathName: window.location.pathname,
            correlationId: getClientCorrelationId()
        })
    }
}
