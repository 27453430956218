import {IPublicClientApplication, AccountInfo} from "@azure/msal-browser";
import {ApiRequestLogger} from "../logger/apiRequestLogger";
import {getClientCorrelationId} from "../CorrelationIDSession";
import {AuthorizationError} from "@Praia-Health/spi-common-utils/dist/errors";

export default async function getAccessToken(instance: IPublicClientApplication, accounts: AccountInfo[], scopes: string[], log: ApiRequestLogger): Promise<string> {
    return await instance.acquireTokenSilent({
        account: accounts[0],
        scopes,
        correlationId: getClientCorrelationId()
    }).then((response: { accessToken: any; }) => {
        if (response.accessToken) {
            return response.accessToken;
        }
        log.error(`getAccessToken(): Failed to acquire token` );
        throw new AuthorizationError("Failed to acquire token");
    }).catch((error) => {
        log.error(`getAccessToken(): Failed to acquire token ${error.message}`);
        throw error;
    });
}
