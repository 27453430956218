import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function existingPasswordControl(control: any, errors: any, label: string) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const {t} = useTranslation();
    const inputProps = {
        endAdornment:
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>,
    };

    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="existingPassword"
            rules={{
                required: true,
            }}
            fullWidth
            id="existingPassword"
            type={showPassword ? "text" : "password"}
            label={label}
            error={errors.existingPassword?.type === "required"}
            helperText={errors.existingPassword?.type === "required" && t("Existing password is required")}
            InputProps={inputProps}
            autoComplete="current-password"
        />);
}
