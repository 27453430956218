import { AmplitudeEvent, AuthType } from '@Praia-Health/spi-amplitude-client/dist/types/amplitudeConfig';
import AmplitudeBrowserClient from '@Praia-Health/spi-amplitude-client/dist/amplitudeBrowserClient';
import { AmplitudeClientConfig } from '@Praia-Health/spi-amplitude-client/dist/types/amplitudeConfig';
import { BrowserClient } from '@amplitude/analytics-types';
import { AmplitudeIdentifiers } from '../../types/clientConfigTypes';
import { getDeviceType } from '../util/userAgentUtils';
import { getClientCorrelationId } from '../CorrelationIDSession';

let ampClient: AmplitudeBrowserClient;
let brand: string;
let isMobile: boolean;
let userId: string;

export enum appPromotionEvents {
    VIEW_APP_PROMOTION = "SSO - App Promo - Shown",
    SKIP_APP_PROMOTION = "SSO - App Promo - Skip",
    DOWNLOAD_APP_FROM_PROMOTION = "SSO - App Promo - Download App"
}

export const initAmplitude = (config: AmplitudeClientConfig, brandInput: string, isMob: boolean, customDeviceId?: string, customSessionId?: string) => {
    brand = brandInput;
    isMobile = isMob;
    ampClient = AmplitudeBrowserClient.getInstance(config, customDeviceId, customSessionId);
};

export const getAmplitudeIdentifiers = () => {
    const browserClient = ampClient.amplitudeClient as BrowserClient;
    return { amplitudeDeviceId: browserClient.getDeviceId(), amplitudeSessionId: browserClient.getSessionId() } as AmplitudeIdentifiers;
}

export const AddSiteInfoToEvent = (event: AmplitudeEvent): AmplitudeEvent => {
    const eventPropsWithSiteInfo = {
        ...event.event_properties,
        correlationId: getClientCorrelationId() || '',
        referring_site_domain: isMobile ? `cep_${brand}` : window.location.hostname,
        referring_site_url: isMobile ? "" : window.location.hostname + window.location.pathname
    };
    return { ...event, event_properties: eventPropsWithSiteInfo };
};

export const setUserId = (accountId: string) => {
    userId = accountId;
};

export type EventProperties = { [key: string]: string };

type SendEventOptions = {
    eventType: string;
    jwt?: string;
    eventProperties?: EventProperties
}

export const sendEventByOptions = (options: SendEventOptions) => {
    const { eventType, jwt, eventProperties } = options;

    const eventPropsData: AmplitudeEvent = {
        "event_type": eventType
    };

    if (eventProperties) {
        eventPropsData.event_properties = { ...eventProperties, correlationId: getClientCorrelationId() || '' };
    }

    if (jwt) {
        eventPropsData.user_id = userId;
        ampClient.trackEvent(eventPropsData, AuthType.JWT, jwt)
    } else {
        ampClient.trackEvent(eventPropsData);
    }
};

export const sendEventByType = (eventType: string, jwt?: string) => {
    const eventPropsData: AmplitudeEvent = {
        "event_type": eventType
    };
    if (jwt) {
        eventPropsData.user_id = userId;
    }
    jwt ? ampClient.trackEvent(AddSiteInfoToEvent(eventPropsData), AuthType.JWT, jwt) : ampClient.trackEvent(AddSiteInfoToEvent(eventPropsData));
};

export const sendEventbyIdVerification = (jwt: string, eventType: string, identityVerification: string) => {
    const eventPropsData: AmplitudeEvent = {
        "event_type": eventType,
        "user_id": userId,
        "event_properties": {
            "identity_verification": identityVerification
        }
    };
    jwt && ampClient.trackEvent(AddSiteInfoToEvent(eventPropsData), AuthType.JWT, jwt);
};

export const sendEventByPhoneNumber = (jwt: string, eventType: string, identityVerification: string, phoneNumber: string) => {
    const eventPropsData: AmplitudeEvent = {
        "event_type": eventType,
        "user_id": userId,
        "event_properties": {
            "identity_verification": identityVerification,
            "phone_number": phoneNumber
        }
    };
    jwt && ampClient.trackEvent(AddSiteInfoToEvent(eventPropsData), AuthType.JWT, jwt);
};

export const sendEventByEventObject = (jwt: string, event: AmplitudeEvent) => {
    if (jwt) {
        ampClient.trackEvent(AddSiteInfoToEvent(event), AuthType.JWT, jwt);
    }
};

export const sendEventForAIA = (jwt: string, eventType: string) => {
    const eventPropsData: AmplitudeEvent = {
        "event_type": eventType,
        "user_id": userId,
        "event_properties": {
            "correlationId": getClientCorrelationId() || '',
            "activation_method": "AIA",
            "referring_site_domain": window.location.hostname,
        }
    };
    if (jwt) {
        ampClient.trackEvent(eventPropsData, AuthType.JWT, jwt);
    }
};

export const sendAppPromotionForAIA = (jwt: string, eventType: string, brandName: string) => {
    const eventPropsData: AmplitudeEvent = {
        "event_type": eventType,
        "user_id": userId,
        "event_properties": {
            "correlationId": getClientCorrelationId() || '',
            "activation_method": "AIA",
            "referring_site_domain": window.location.hostname,
            "promo_creative": getDeviceType(),
            "brand": brandName
        }
    };
    if (jwt) {
        return ampClient.trackEvent(eventPropsData, AuthType.JWT, jwt);
    }
};
