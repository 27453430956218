/**
 * Accepts a 10-digit phone number string and returns it in the format for "(xxx) xxx-xxxx"
 * @param phoneNumberString
 */
import {BadRequestError} from "@Praia-Health/spi-common-utils/dist/errors";

export function formatPhoneNumber(phoneNumberString: string) {
    if (phoneNumberString && phoneNumberString.length === 10) {
        const formatted = phoneNumberString.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3");
        if (phoneNumberString ===  formatted) { // phone number no valid
            throw new BadRequestError("formatPhoneNumber(): phoneNumberString cannot be formatted.");
        }
        return formatted;
    } else {
        throw new BadRequestError("formatPhoneNumber(): expects a 10-digit number.");
    }
}
