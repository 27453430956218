import {createContext} from "react";
import {AppPromotionMode, ClientConfigType} from "../types/clientConfigTypes";

export const ClientConfigContext = createContext<ClientConfigType>({
    bypassProve: false,
    customer: "",
    customerSupportNumber: "",
    brandConfigs: [],
    i18nPath: "",
    pimsApiUrl: "",
    myChartConfig: {
        autoInstantActivationMapping: {},
        mychartPrefix: "",
        mychartDomains: []
    },
    myChartRedirectBaseUrls: [],
    tier: "",
    b2cTenantConfig: {
        tenantName: "",
        tenantId: "",
        signInPolicies: [],
        clientId: "",
        scopes: [],
        redirectUri: "",
        postLogoutRedirectUri: "",
    },
    cdnPath: "",
    userActivityLimitMs: 900000,
    mfaConfig: {
        checkMarkIconUri: "",
        securityIconUri: "",
        showResendButtonInMilliSecond: 1000,
        verificationCodeExpireTimeInSeconds: 1,
        mfaEnabled: true
    },
    amplitudeConfig:{
        authUrl:"",
        noAuthUrl:"",
        enableEvents:true
    },
    appPromotion: {
        brands: [],
        devices: [],
        enable:false,
        mode: AppPromotionMode.Banner
    },
    version: ""
});
