import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import BrandedCard, { BrandedCardContent, BrandedCardHeader } from '../../containers/BrandedCard_v2';
import { AmplitudeEventType, ClientConfigType } from "../../../../types/clientConfigTypes";
import { ClientConfigContext } from "../../../clientContext";
import {formatPhoneNumber} from "../../../../utils/formatPhoneNumber";
import { ApiRequestLogger } from '../../../logger/apiRequestLogger';
import TypographyI18N from '../../elements/TypographyI18N';
import { useErrorLogging } from '../../../util/errorUtils';
import { useAnalytics } from '../../../hooks/useAnalytics';

function AutoInstantActivationErrorPage(): JSX.Element {
    const log = new ApiRequestLogger({ eventSource: 'AutoInstantActivationErrorPage' });
    const { sendEvent } = useAnalytics({ eventSource: '', useJWT: false });
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const {t} = useTranslation();
    useErrorLogging(log);

    useEffect(()=>{
        sendEvent(AmplitudeEventType.IdentityVerification_ContactService);
    },[])
    

    return (
        <BrandedCard>
            <BrandedCardHeader>
                <TypographyI18N variant="h2">Contact customer service</TypographyI18N>
            </BrandedCardHeader>
            <BrandedCardContent>
                <Typography variant="h2">
                    {t('Your link is not valid. To request a new link, please call customer service toll-free at')}<br/>
                    <a href={"tel:" + clientConfiguration.customerSupportNumber}> {formatPhoneNumber(clientConfiguration.customerSupportNumber)} </a>.
                </Typography>
            </BrandedCardContent>
        </BrandedCard>
    );
}

export default AutoInstantActivationErrorPage;
