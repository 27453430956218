import { Grid, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(() => ({

    root: {
        alignItems: "center",
        height: 104,
        justifyContent: (isArray: boolean) => {
            return (isArray) ? "space-between" : "space-evenly";
        },
        paddingLeft: 60,
        paddingRight: 60,
    },
}));

export type BrandedCardActionsProps = {
    children: ReactNode | ReactNode[]
}

const BrandedCardActions = (props: BrandedCardActionsProps) => {
    const { children } = props;
    const classes = useStyles(Array.isArray(children));
    return (
        <Grid item container wrap='nowrap' className={classes.root} xs={12}>
            {children}
        </Grid>
    );
}
BrandedCardActions.displayName = 'BrandedCardActions';
export default BrandedCardActions;
