import React, { useContext, useEffect, useState, useRef } from 'react';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

import getAccessToken from "../../../util/getAccessToken";
import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import verificationCodeControl from '../../controls/VerificationCodeControl';
import { verifyCode, sendVerificationCode } from '../../../services/pimsClient';
import { ClientConfigContext } from "../../../clientContext";
import { BrandConfig, ClientConfigType, MFAVerifyType } from "../../../../types/clientConfigTypes";
import Button from '../../elements/Button';
import Icon from '../../elements/Icon';
import {ApiRequestLogger} from "../../../logger/apiRequestLogger";

interface NewLocation extends Location {
    state: {
        emailId: string;
    }
}

type MfaCodeVerificationProps = {
    brandInfo: BrandConfig,
    verifyType: MFAVerifyType
}

function MfaCodeVerificationPage(props: MfaCodeVerificationProps) {
    const { instance, accounts } = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const history = useHistory();
    const location: NewLocation = useLocation();
    const { handleSubmit, errors, control, formState, reset, setError, clearErrors } = useForm({ mode: 'onChange' });
    const emailId = location.state?.emailId ?? "";
    const [loading, setLoading] = useState(false);
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const [codeExpireTimer, setCodeExpireTimer] = useState(clientConfiguration.mfaConfig.verificationCodeExpireTimeInSeconds);
    const [showResend, setShowResend] = useState(false);
    const [isTimeOutOrResend, setIsTimeOutOrResend] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const log = new ApiRequestLogger({ eventSource: 'MfaCodeVerificationPage' });
    const isMountedRef = useRef(true);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        setIsInvalidCode(false);
        const enableResendButton = setTimeout(() => {
            setShowResend(true);
        }, clientConfiguration.mfaConfig.showResendButtonInMilliSecond);
        return () => {
            isMountedRef.current = false;
            clearTimeout(enableResendButton);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (codeExpireTimer !== 0) {
                setCodeExpireTimer(codeExpireTimer - 1);
            }
        }, 1000)

        return () =>
            clearInterval(interval);
    }, [codeExpireTimer]);

    const onSubmit = async (data: any) => {
        if (isMountedRef.current) {
            setLoading(true);
        }
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
        try {
            setInvalid(false);
            log.info("MFA Setup: Validating Email verification code.");
            const response = await verifyCode({
                jwt, code: data.verificationCode, pimsApiUrl: clientConfiguration.pimsApiUrl, mode: props.verifyType, email: emailId
            });
            if (response.ok) {
                history.push({
                    pathname: "/success"
                });
                log.info("MFA Setup: Email verification code valid.");
            } else {
                setLoading(false);
                setInvalid(true);
                setIsTimeOutOrResend(false);
                setIsInvalidCode(true);
                setError('Invalid Verification Code', { type: 'custom', message: 'Entered Code is invalid' });
                log.info("MFA Setup: Email verification code invalid.");
            }
        } catch (err: any) {
            log.error(`Error occurred - ${err.status}`);
            history.push("/error");
        }
    };

    const resendVerification = async (event: any) => {
        event.preventDefault();
        if (isMountedRef.current) {
            setLoading(true);
        }
        const jwt = await getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log);
        try {
            const response = await sendVerificationCode({
                jwt, value: emailId, pimsApiUrl: clientConfiguration.pimsApiUrl, language: i18n.languages[0], mode: MFAVerifyType.EmailVerifyType
            });
            if (response.ok) {
                if (isMountedRef.current) {
                    setLoading(false);
                    setIsTimeOutOrResend(true);
                    setCodeExpireTimer(clientConfiguration.mfaConfig.verificationCodeExpireTimeInSeconds)
                    setInvalid(false);
                    setIsInvalidCode(false);
                    clearErrors();
                    reset([""]);
                    log.info("MFA Setup: Email verification code Sent.");
                }
            } else {
                log.error(`MFA Setup: Sent verification code fail. Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                history.push("/error");
            }
        } catch (err: any) {
            log.error(`Error occurred - ${err.status}`);
            history.push("/error");
        }
    };

    const { isDirty, isValid } = formState;
    const renderContent = () => {
        return (
            <Grid container justify="center" item xs={12}>
                <Grid item xs={12} className="mb-1">
                    {isInvalidCode ? null : <Typography variant="body1">
                        <Trans
                            defaults="verification code Description"
                            components={{ bold: <strong /> }} />
                        {t("The code will be valid for ")}:
                    </Typography>}
                </Grid>
                {!isInvalidCode ?
                    <Grid item xs={12} className="txtAlignCenter mb-1" >
                        <Typography variant="h2" color="primary"> {`${Math.floor(codeExpireTimer / 60)}m : ${('0' + (codeExpireTimer % 60)).slice(-2)}s`} </Typography>
                    </Grid> : null}

                <Grid item xs={12}>
                    <div className={(isInvalidCode) ? 'input-error' : ''}>
                        {verificationCodeControl(control, errors, t, undefined, invalid)}
                    </div>
                </Grid>
            </Grid>
        );

    }

    const renderActions = () => {
        return (
            <Grid container justify="center" item xs={12} className="minHeight-7">
                <Grid container justify="center" item xs={12}>
                    <Button disabled={!isDirty || !isValid} type="submit" variant="contained" widthOverride="200px">
                        {
                            loading ? <div className="btn-spinner">
                                <CircularProgress />
                            </div> : t('Verify')
                        }
                    </Button>
                </Grid>
                {showResend && !isTimeOutOrResend ?
                    <Grid item xs={12}>
                        <Typography variant="body1" align="center" className='mt-1'>
                            {t("Didn't receive your code?")}&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link className="txtDecorationNone" to="/mfa-verification-code/reload" onClick={(e) => resendVerification(e)}>
                                {t('Resend code')}
                            </Link>
                        </Typography>
                    </Grid> : null}

                {isTimeOutOrResend ?
                    <div className='dFlex my-1'>
                        <Typography variant="subtitle2" align="center">
                            {t('NEW Verification code sent!')}&nbsp;&nbsp;
                        </Typography>
                        <div className="alignSelfCenter">
                            <Icon size={20} iconUri={`${clientConfiguration.cdnPath}${clientConfiguration.mfaConfig.checkMarkIconUri}`} altText={""} />
                        </div>
                    </div>
                    : null}

                {isTimeOutOrResend ? <Typography variant="body1" align="center">
                    {t('Previous sent code is now invalid. Enter your latest code.')}
                </Typography> : null}

            </Grid>
        );
    }

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <BrandedCard brand={props.brandInfo} >
                        {{
                            header: (<>
                                <Grid item container spacing={3} >
                                    <Grid item xs={12}>
                                        <Icon size={63.74} iconUri={`${clientConfiguration.cdnPath}${clientConfiguration.mfaConfig.securityIconUri}`} altText={""} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {isInvalidCode ? <Typography variant="h2">
                                            {t("Something went wrong...")}
                                        </Typography> :
                                            <Typography variant="h2">
                                                {t("Enter your two step verification code")}
                                            </Typography>}
                                    </Grid>

                                    {isInvalidCode ? <Grid item xs={12}><Typography variant="body1" color='error'>
                                        {t("You have entered an invalid code. Please try again.")}
                                    </Typography></Grid> : null}

                                </Grid>
                            </>),
                            content: (<>
                                {renderContent()}
                            </>),
                            actions: (<>
                                {renderActions()}
                            </>
                            )
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );

}

export default MfaCodeVerificationPage;
