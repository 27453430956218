import {PimsConsumer} from "../types/consumer";

/**
 * Returns the requested EHR system if the user is authorized for that system.
 * Supports cross-region login when the user is authorized for a different system than the requested system.
 *
 * Scenario 1: Returns requested EHR system when user is authorized for it.
 * Scenario 2: Returns first authorized EHR system when user is unauthorized for the requested system.
 * Scenario 3: Returns first authorized EHR system when no specific EHR system is requested.
 * Scenario 4: Returns null when user is unauthorized for all EHR systems.
 */
export function pickAuthorizedSystem(consumer: PimsConsumer, ehrSystem?: string): string | null {
    if (consumer && consumer.systemAuthorizations && consumer.systemAuthorizations.length > 0) {
        if (ehrSystem) {
            if (consumer.systemAuthorizations.includes(ehrSystem)) {
                return ehrSystem; // SCENARIO 1
            } else {
                return consumer.systemAuthorizations[0]; // SCENARIO 2
            }
        } else {
            return consumer.systemAuthorizations[0]; // SCENARIO 3
        }
    }
    return null; // SCENARIO 4
}

/**
 * Returns true if the user is authorized for that system (if specified).
 * Otherwise, it is true if it's authorized for any system or false if it is unauthorized.
 */
export function hasAuthorizedSystem(consumer: PimsConsumer, ehrSystem?: string) {
    if (consumer && consumer.systemAuthorizations && consumer.systemAuthorizations.length > 0) {
        if (ehrSystem) {
            return consumer.systemAuthorizations.includes(ehrSystem);
        } else {
            return true;
        }
    }
    return false;
}
