import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import { Button } from '../../elements/Button';
import {isRedirectUrlValid} from "../../../services/validation";
import {BrandProps} from "../../../../types/clientConfigTypes";
import {OPENID_PARAMS} from "../../../../types/openid_enum";
import { sendEventByType } from '../../../services/amplitudeClient';
import { AmplitudeEventType } from '../../../../types/clientConfigTypes';

function UsernameRecoveryHandOff({brandInfo}: BrandProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const search = document.location.search;
    const searchParams = new URLSearchParams(search);
    const redirectUrl = searchParams.get(OPENID_PARAMS.REDIRECT_URI) || searchParams.get(OPENID_PARAMS.REDIRECT_URL) as string;

    useEffect(() =>{
        sendEventByType(AmplitudeEventType.UsernameRecovery_Confirm);
    },[]);
    const redirect = async () => {
        sendEventByType(AmplitudeEventType.UsernameRecovery_SignIn);
        if (redirectUrl) {
            const isValid = await isRedirectUrlValid(redirectUrl);
            if (isValid) {
                window.location.href = redirectUrl;
            } else {
                history.push("/error");
            }
        } else {
            history.push("/error");
        }
    };

    return (
        <>
            <BrandedCard brand={brandInfo} >
                {{
                    header: (<>
                        <Typography variant="h2">
                            {t('Username recovery')}
                        </Typography>
                    </>),
                    content: (<>
                        <Grid container justify="center" item xs={10} className="txtAlignCenter">
                            <Typography variant="h3">
                                {t('We will send you an email with your verified username at the email associated with your account.')}
                            </Typography>
                        </Grid>
                    </>),
                    actions: (
                        <>
                            <Grid container justify="center" item xs={12}>
                                <Grid container justify="center" item xs={3}>
                                    <Button type="button" variant="contained" onClick={() => redirect()}>{t('Sign In')}</Button>
                                </Grid>
                            </Grid>
                        </>
                    )
                }}
            </BrandedCard>
        </>
    );
}

export default UsernameRecoveryHandOff;
