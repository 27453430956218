import { Grid, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(() => ({
    root: {
        alignItems: "center",
        minHeight: 286,
        justifyContent: "center",
        paddingLeft: 60,
        paddingRight: 60,
    },
}));

export type BrandedCardContentProps = {
    children: ReactNode | ReactNode[]
}

const BrandedCardContent = (props: BrandedCardContentProps) => {
    const classes = useStyles();
    const { children } = props;

    return (
        <Grid item className={classes.root} xs={12}>
            {children}
        </Grid>
    );
}
BrandedCardContent.displayName = 'BrandedCardContent';
export default BrandedCardContent;

