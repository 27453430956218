
/**
 * Simple helper to build URL Search Params from an object of strings and bools.
 * Undefined and null values are not included in the URLSearchParams.
 */
export const buildUrlParams = (params: {[key: string]: string | boolean | null | undefined}): URLSearchParams => {
    const urlSearchParams = new URLSearchParams();

    for (const key in params) {
        const value = params[key];
        if (typeof value === 'boolean') {
            urlSearchParams.set(key, value ? 'true' : 'false');
        } else if (typeof value === 'string') {
            urlSearchParams.set(key, value);
        }
    }

    return urlSearchParams;
}
