import { Theme, ThemeOptions, createMuiTheme } from "@material-ui/core";
import { theme as defaultTheme } from '../../assets/themes/theme';
import { merge } from "ts-deepmerge";

// TODO: Turn this into a theme provider by wrapping the mui themeprovider
const getTheme = (brandTheme: ThemeOptions = {}): Theme => {

    // Deep merge the themes together
    const themeOptions: ThemeOptions = merge(defaultTheme, brandTheme) as ThemeOptions;

    return createMuiTheme(themeOptions);
}

export default getTheme;
