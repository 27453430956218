import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

export default function firstNameControl(control: any, errors: any, shrink?: boolean) {
    const {t} = useTranslation();
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="firstName"
            rules={{
                required: true
            }}
            fullWidth
            id="firstName"
            label={(errors.firstName?.type === "required" && <div>{t("First name ")}<div className="errorLabelAsterisk">*</div></div>) ||
            t("First name")}
            error={errors.firstName?.type === "required" || errors.firstName?.type === "validate"}
            helperText=
                {(errors.firstName?.type === "required" && "Required") ||
                (errors.firstName?.type === "validate" && t("Please enter your first name")) ||
                (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
            autoComplete="given-name"
        />);
}
