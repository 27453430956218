


import { Grid, Theme, makeStyles } from '@material-ui/core';
import React from 'react';
import { useBrand } from '../../../hooks/useBrand';

const useStyles = makeStyles<Theme> ((theme) => ({
    helmet: {
        background: theme.palette.primary.dark,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        display: "block",
        height: 40
    },
}));

const BrandedCardHelmet = () => {
    const brand = useBrand();
    const classes = useStyles(brand);

    return (
        <Grid item xs={12} className={classes.helmet}>&nbsp;</Grid>
    );
}
BrandedCardHelmet.displayName = 'BrandedCardHelmet';
export default BrandedCardHelmet;
