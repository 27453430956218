import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from '@material-ui/core';

export default function cityControl(control: any, errors: any, shrink?: boolean) {
    const {t} = useTranslation();
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="city"
            rules={{
                required: true,
                pattern: /^[a-zA-Z\s]+(?:[\s-][a-zA-Z\s]+)*$/
            }}
            fullWidth
            id="city"
            label={(errors.city?.type === "required" && <div>{t("City ")}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.city?.type === "pattern" && <div>{t("City ")}<div className="errorLabelAsterisk">*</div></div>) ||
             t("City")}
            error={errors.city?.type === "required" || errors.city?.type === "pattern"}
            helperText=
            {(errors.city?.type === "required" && t("City is required")) ||
            (errors.city?.type === "pattern" && t("City is incorrect")) ||
            (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
        />);
}
