import React from 'react';

type IconProps = {
    iconUri: string,
    altText: string,
    size: number,
}

const Icon = (props: IconProps) => {

    return <img src={props.iconUri} alt={props.altText} width={props.size} />;

};

export default Icon;
