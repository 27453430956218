import {
  MyChartConfig,
  MyChartDomain
} from "@Praia-Health/spi-common-utils/dist/mychart/types/mychartConfigTypes";
import {ClientConfigType} from "../../types/clientConfigTypes";
import {getDefaultBrand} from "./brandUtils";

export function getMyChartSamlSSOFromSystemAndBrand(clientConfiguration: ClientConfigType, system: string, brand: string): string {
  let foundDomain = findMyChartBySystemAndBrand(clientConfiguration.myChartConfig, system, brand);
  if (!foundDomain) {
    const defaultBrand = getDefaultBrand(clientConfiguration);
    foundDomain = findMyChartBySystemAndBrand(clientConfiguration.myChartConfig, system, defaultBrand);
  }

  return `https://${foundDomain.domain}/${foundDomain.subDomain}/Authentication/Saml/Login?idp=SSO`;
}

export function getMyChartLandingPageFromSystemAndBrand(clientConfiguration: ClientConfigType, system: string, brand: string): string | undefined {
  let foundDomain = findMyChartBySystemAndBrand(clientConfiguration.myChartConfig, system, brand);
  if (!foundDomain) {
    const defaultBrand = getDefaultBrand(clientConfiguration);
    foundDomain = findMyChartBySystemAndBrand(clientConfiguration.myChartConfig, system, defaultBrand);
  }

  return `https://${foundDomain.domain}/${foundDomain.subDomain}/Authentication/Login`;
}

function findMyChartBySystemAndBrand(myChartConfig: MyChartConfig, system: string, brand: string): MyChartDomain {
  const myDomains = myChartConfig.mychartDomains;
  const foundDomain = myDomains.find(myDomain => {
    return myDomain.brand.toLowerCase() === brand?.toLowerCase() && system.endsWith(myDomain.epic)
  });
  return foundDomain as MyChartDomain;
}

export function getSystemFromMyChartUrl(clientConfig: ClientConfigType, url: string) {
  if (!url) {
    throw new Error("getSystemFromMyChartUrl(): URL is missing");
  }
  const mychartConfig = clientConfig.myChartConfig;

  const mychartDomain = mychartConfig.mychartDomains.find((mychartSite: MyChartDomain) => {
    return url.includes(mychartSite.domain);
  });

  if (mychartDomain) {
    return getFullyQualifiedEhrSystemName(clientConfig, mychartDomain.epic);
  } else {
    throw new Error(`URL: ${url} does not map to any known EHR system`);
  }
}

export function getFullyQualifiedEhrSystemName(clientConfig: ClientConfigType, ehrRegion: string) {
  return `org.${clientConfig.customer}.dig.epic.${ehrRegion}`;
}
