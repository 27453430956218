import React from 'react';
import TypographyI18N from '../../elements/TypographyI18N';

type AutoInstantActivationCardHeader = {
    loading: boolean
}

function AutoInstantActivationCardHeader(props: AutoInstantActivationCardHeader) {
    if (props.loading) {
        return (
            <TypographyI18N variant="h2">Creating your account</TypographyI18N>
        );
    } else {
        return (
            <TypographyI18N variant="h2">Help us find your medical record</TypographyI18N>
        );
    }
}

export default AutoInstantActivationCardHeader;