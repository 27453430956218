import { ApiRequestLogger } from '../logger/apiRequestLogger';
import { OidcState } from '../../types/oidcState';
import { useHistory } from 'react-router-dom';
import { createErrorParams } from '../util/errorUtils';
import { fetchOidcState } from "../util/oidcStateExtractUtils";
import { OPENID_PARAMS } from '../../types/openid_enum';

type HistoryType = ReturnType<typeof useHistory>;

type LoggingParams = {
    log: ApiRequestLogger;
    errorPath?: string;
}

type ErrorHandlerOptions = LoggingParams & {
    message: string;
    rootCause?: string;
    history: HistoryType;
}

type UseOIDCStateOptions = LoggingParams &  {
    isRequired: boolean;
    onError?: (options: ErrorHandlerOptions) => void;
}

const defaultErrorHandler = ({ message, rootCause, history, log, errorPath }: ErrorHandlerOptions) =>{
    log.error(`${message}: ${rootCause}`);
    history.push({
        pathname: errorPath, 
        search: '?' + createErrorParams(message)
    });
}

/**
 * Get the state from the URL query parameters.
 */
const useOidcState = <T extends OidcState> ({ isRequired, log, errorPath, onError = defaultErrorHandler }: UseOIDCStateOptions): T | undefined => {
    const history = useHistory();

    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const encodedState = searchParams.get(OPENID_PARAMS.STATE) as string;

    if (!encodedState) {
        if (isRequired) {
            onError({ message: 'No state found in the URL', history, log, errorPath });

            log.error('No state found in the URL');
            history.push({
                pathname: errorPath, 
                search: '?' + createErrorParams('No state found in the URL')
            });
        }
        return;
    }

    try {
        return fetchOidcState();
    } catch (err: any) {
        onError({message: 'Error parsing state', rootCause: err.message, history, log, errorPath })
    }
}

export { useOidcState };
