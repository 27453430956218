import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import {conformToMask} from "react-text-mask";

const MASK = [/\d/, /\d/, '/',/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

function formatText(text:string):string{
    const conformedDate = conformToMask(text, MASK, {guide: false});
    const length = conformedDate.conformedValue?.length;
    const m = conformedDate.conformedValue+"MM/DD/YYYY".slice(length);
    return m;
}


/* eslint-disable no-useless-escape */
export default function dateOfBirthControl(control: any, errors: any, shrink?: boolean): JSX.Element {
    const {t} = useTranslation();
    const validateDate = (data: any) => {
        const arr = data.split('/');
        if( arr.length != 3) return false;
        const month = arr[0];
        const date = arr[1];
        const year = arr[2];
        let validationResult = true ;
        //get current date
        const today = new Date()
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1;
        if (year > currentYear || year < currentYear - 180){
            validationResult = false;
        } else if (year == currentYear && month > currentMonth) {
            validationResult = false;
        } else if (year == currentYear && month == currentMonth && date > today.getDate()){
                validationResult = false;
        }
        return validationResult;
    };
    let isBackspace = false; // True if the backspace is pressed
    let deleteNumber = false;  // True if the last character is a number and not 'Y'.
    let caret = 0; // Cursor position
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue="MM/DD/YYYY"
            name="birthdate"
            rules={{
                required: true,
                //MM   : (0[1-9]|1[0-2]). Handles values between 01 to 12
                // DD  : (0[1-9]|[1-2][0-9]|3[0-1]). Handles values between 01 to 31
                // YYYY: 4 digits
                pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/[0-9]{4}$/,
                //validateDate checks whether birth date is not greater than today
                //also birth year is not less than currentYear - 180.
                validate: validateDate
            }}
            // variant={oneSiteStyling ? "outlined" : "standard"}
            fullWidth
            id="birthdate"
            label={(errors.birthdate?.type === "required" && <div>{t("Date of birth ")}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.birthdate?.type === "pattern" && <div>{t("Date of birth ")}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.birthdate?.type === "validate" && <div>{t("Date of birth ")}<div className="errorLabelAsterisk">*</div></div>) ||
             t("Date of birth")}
            inputProps={{
                // defaultValue: "MM/DD/YYYY",
                maxLength: 11,
                onKeyDown: (event:any) => {
                    isBackspace = ['Backspace', 'Delete'].includes(event.key);
                    deleteNumber = event.target.value.endsWith('Y') && isBackspace;
                    caret = event.target.selectionStart;
                },
                onInput:(event:any) => {
                    let text = event.target.value;

                    if (isBackspace){
                        text = conformToMask(text, MASK, {guide: false}).conformedValue;
                        if (text){
                            text = text.replace(/\//g,"");
                            // 'conformToMask' removes any additional digit from the date
                            // So, we will never going to have more than 10 charactes (MM/DD/YYYY)
                            if (deleteNumber && caret === 10){
                                text = text.slice(0, -1);
                            }
                            text = formatText(text)
                        }
                        caret -= 1;
                    } else {
                        text = formatText(text);
                        caret += 1;
                        if (text.charAt(caret)==='/'){
                            caret += 1;
                        }
                    }
                    event.target.value = text
                    const element = event.target;
                    window.requestAnimationFrame(() => {
                        element.selectionStart = caret;
                        element.selectionEnd = caret;
                    });
                },
            }}
            error={errors.birthdate?.type === "required" ||
            errors.birthdate?.type === "pattern" ||
            errors.birthdate?.type === "validate"}
            helperText=
            {(errors.birthdate?.type === "required" && t("Date of birth is required")) ||
            (errors.birthdate?.type === "pattern" && t("Date of birth is incorrect")) ||
            (errors.birthdate?.type === "validate" && t("Date of birth is incorrect")) ||
            (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
            placeholder={t("MM/DD/YYYY")}
            autoComplete="bday"
        />);
}
