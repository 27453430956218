import { Theme, ThemeOptions } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Styles } from '@material-ui/core/styles/withStyles';

export type ButtonStylesType = {
    borderWidth: number;
    fontSize: number;
    fontWeight: number;
    height: number;
    width?: number;
    minWidth?: number;
    backgroundColor: (palette: PaletteOptions) => string;
}

type ButtonStylesThemeOptions = ThemeOptions & {
    breakpoints: {
        up: any,
        down: any
    },
    palette: {
        common: { black: string, white: string },
        primary: { main: string, light: string, dark: string };
        secondary: { 
            main: string,
            light: string,
            dark: string
        };
    },
    shadows: string[];
}

export const buttonStyles: Styles<Theme, ButtonStylesType, string> = (theme: ButtonStylesThemeOptions) => ({
    root: {
        whiteSpace: 'nowrap',
        borderRadius: 24,
        height: options => options.height,
        [theme.breakpoints.down('xs')]: {
            minWidth: options => options.width ?? 120,
            width: 'fit-content'
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: options => options.width ?? 144,
            width: 'fit-content'
        },
        padding: "0 3em",
    },
    label: {
        textTransform: 'uppercase',
        fontSize: options => options.fontSize,
        fontWeight: options => options.fontWeight,
        lineHeight: '19.2px',
        textWrap: 'wrap'
    },
    outlined: {
        color: theme.palette.secondary.main,
        backgroundColor: options => options.backgroundColor(theme.palette),
        borderWidth: options => options.borderWidth,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: 'initial',   
            borderColor: theme.palette.secondary.light
        },
        '&:active': {
            borderColor: theme.palette.secondary.dark
        },
        '&:disabled': {
            borderColor: theme.palette.secondary.main,
            opacity: 0.4
        }
    },
    contained: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        whiteSpace: 'nowrap',
        padding: "0 1em",
        boxShadow: theme.shadows[0],
        '&:hover': {
            boxShadow: theme.shadows[0],
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            boxShadow: theme.shadows[0],
            backgroundColor: theme.palette.primary.dark,
        },
        '&:disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.4
        }
    },
    text: {
        color: theme.palette.secondary.dark,
        '&:hover': {
            color: theme.palette.secondary.light,
            backgroundColor: 'initial',
        },
        '&:active': {
            color: theme.palette.secondary.light
        },
    }
});
