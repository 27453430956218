import { AppPromotionConfig, AppPromotionDeviceConfig, BrandConfig, PromotionConfig } from "../../types/clientConfigTypes";
import i18n from "i18next";
import { History } from 'history';
import { getDeviceType, getMobileOperatingSystem } from "./userAgentUtils";

export function showAppPromotion(promotion: PromotionConfig): boolean { 
    return promotion.isEnabled && i18n.language !== "es";
}

export const savePromotionStateAndRedirect = (redirectUrl: string) => {
    // Saving the state before redirecting to store
    // Again the promotion Dialog wil be shown, using the state of the promotion
    // pushState fn alone is not suficient to store the state (promotion state)
    // using session storage to restore the state
    window.history.pushState(null, "", window.location.href);
    sessionStorage.setItem("promotion", "true");
    window.location.replace(redirectUrl);
}

export const navigateToPromotionPage = (history: History, redirectUrl: string) => {
    history.push({
        pathname: "/promotion",
        state: { redirectUrl: redirectUrl }
    });
}

export const checkSessionAndNavigateToPromotionPage = (history: History, redirectUrl: string) => {
    const isPromotionStatePresent = sessionStorage.getItem("promotion");
    if(isPromotionStatePresent && isPromotionStatePresent === 'true') {
        // User navigated to the Play Store/APP Store and returned back using back button browser, therefore again showing App promotion
        // so that, user can close the app promotion and redirect to Mychart
        sessionStorage.removeItem("promotion");
        navigateToPromotionPage(history, redirectUrl);
    }
}

// App Promotion Banner and Full Page

/**
 * Returns the iOS or Android config for app promotion
 * 
 * @param brandConfig The brand configuration from /api/config
 * @returns The device config for the current device
 */
export const getDeviceConfig = (brandConfig: BrandConfig): AppPromotionDeviceConfig => {
    const mobileOperatingSystem = getMobileOperatingSystem();
    if (brandConfig.promotion && brandConfig.promotion.apps) {
        const deviceConfig = brandConfig.promotion.apps.find(app => app.device === mobileOperatingSystem);
        if (deviceConfig) {
            return deviceConfig;
        }
    }
    throw new Error(`No promotion link found for the current device: ${mobileOperatingSystem}`);
}

export const showPromotionForBrand = (brand: string, config:AppPromotionConfig): boolean => {
    return config.brands.includes("all") || config.brands.includes(brand);
}

export const showPromotionForDevice = (config:AppPromotionConfig): boolean => {
    let device = getDeviceType();
    if ( device === "Mobile"){
        device = getMobileOperatingSystem();
    }
    return config.devices.includes(device?.toLowerCase());
}

export const isTodayBetween = (startDate: string, endDate:string): boolean => {
    if (!startDate && !endDate){
        return true;
    }
    const today = new Date();
    if (startDate && ( new Date(startDate).valueOf() > today.valueOf()) ){
        return false;
    }
    if (endDate && ( today.valueOf() > new Date(endDate).valueOf()) ){
        return false;
    }
    return true;
}

export const APP_PROMOTION_DISMISSED_KEY = "appPromotionDismissed";
export const APP_PROMOTION_DISMISSED_START_KEY = "appPromotionDismissedStart"

/**
 * LocalStorage:
 * - appPromotionDismissed: Total dismisses
 * - appPromotionDismissedStart: Date of the first dismissed.
 */
export const incrementDismissed = () => {
    const dismissed = localStorage.getItem(APP_PROMOTION_DISMISSED_KEY) || "0";
    if (dismissed == "0"){
        // Set "Today" date
        localStorage.setItem(APP_PROMOTION_DISMISSED_START_KEY, new Date().valueOf().toString())
    }
    const total = Number(dismissed) + 1;
    localStorage.setItem(APP_PROMOTION_DISMISSED_KEY, total.toString());
}

/**
 * 
 * @param firstDismissedDate the stored time value in milliseconds since midnight, January 1, 1970 UTC.
 * @param resetDismissed <number><unit> (d: day, m: month, y:year)
 * @returns Date if it is possible to calculate; otherwise, it will return undefined. Undefined is when 'firstDismissedDate' or 'resetDismissed' don't exist or are invalid.
 */
export const calculateResetDismissedDate = (firstDismissedDate:string, resetDismissed:string):Date|undefined => {
    if (!resetDismissed || typeof resetDismissed !== 'string' || !firstDismissedDate){
        return undefined;
    }
    const fdd = new Date(Number(firstDismissedDate));
    const unit = resetDismissed.slice(-1); //Gets the unit
    const measure = Number(resetDismissed.slice(0,-1));
    switch(unit){
        case "d":
            fdd.setDate(fdd.getDate() + measure);
            break;
        case "m":
            fdd.setMonth(fdd.getMonth() + measure);
            break;
        case "y":
            fdd.setFullYear(fdd.getFullYear() + measure);
            break;
        default:
            return undefined;
    }
    return fdd;
}

/**
 * 
 * This function determines if the app promotion can be showed depending of the dismissed conditions.
 * 
 * @param maxDismissed Value from the configuration. It can be undefined (Infite dismissed allowed) or an int.
 * @param resetDismissed Value from the configuration. It can be undefined (No reset date), days (<number>d), months (<number>m), or years (<number>y).
 * @returns boolean True is it can be dismissed the app promotion (It shows the promotion)
 */
export const canBeDismissed = (maxDismissed:number|undefined, resetDismissed:string):boolean => {
    if (!maxDismissed || typeof maxDismissed !== 'number') {
        return true;
    }
    let dismissed = localStorage.getItem(APP_PROMOTION_DISMISSED_KEY) || "0";
    const firstDismissedDate = localStorage.getItem(APP_PROMOTION_DISMISSED_START_KEY);
    let resetDismissedDate = undefined;
    if (firstDismissedDate && resetDismissed){
        resetDismissedDate =  calculateResetDismissedDate(firstDismissedDate, resetDismissed);
    }
    if (resetDismissedDate && resetDismissedDate.valueOf() < new Date().valueOf()){
        // Reset the Dismissed counting from the localStorage
        dismissed = "0";
        localStorage.removeItem(APP_PROMOTION_DISMISSED_KEY);
        localStorage.removeItem(APP_PROMOTION_DISMISSED_START_KEY);
    }
    if (Number(dismissed) <= maxDismissed ){
        return true;
    }
    return false;
}

export const shouldShowAppPromotion = (config: AppPromotionConfig, brand: string): boolean => {
    if (!config.enable) {
        console.log("App Promotion disabled");
        return false;
    }
    const brandCondition = showPromotionForBrand(brand, config);
    if (!brandCondition){
        console.log(`Brand ${brand} is not in the whitelist.`)
        return false;
    }
    const deviceCondition = showPromotionForDevice(config);
    if (!deviceCondition) {
        console.log("Device is not in the whitelist.")
        return false;
    }
    const datetimeCondition = isTodayBetween(config.startTime || "", config.endTime || "");
    if (!datetimeCondition){
        console.log("The date is out of the activate date range.")
        return false;
    }
    const dismissedCondition = canBeDismissed(config.maxDismissed, config.resetDismissed || "");
    if (!dismissedCondition){
        console.log("Dismissed condition invalid")
        return false;
    }
    return true;
}