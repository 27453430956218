import React, { ReactNode } from 'react';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import LegalLinks from '../../elements/LegalLinks';
import BrandedCardHelmet from './BrandedCardHelmet';
import BrandedCardLogo from './BrandedCardLogo';
import { useBrand } from '../../../hooks/useBrand';
import Loader from 'react-loader';
import {SpinnerProperties} from '../../../../assets/themes/spinnerProperties';
import TypographyI18N from '../../elements/TypographyI18N';

const useStyles = makeStyles<Theme, BrandedCardProps> (() => ({
    // Container that centers the card in the middle of the page and controls the width of the card
    middleContainer: {
        alignContent: "center",
        alignItems: "center",
        maxWidth: props => props.maxWidth,
        paddingLeft: 0,
        paddingRight: 0,
    },
    // Container that organizes the content of the card vertically and controls the height of the card
    innerContainer: {
        alignContent: "flex-start",
        alignItems: "center",
        backgroundColor: 'white',
        borderRadius: 10,
        justifyContent: "center",
        minHeight: props => props.minHeight,

        // temporary hack until .loadedContent 90% width css rule is removed
        "& .loadedContent": {
            width: "100%"
        }
    },
    loaderText: {
        position: "relative",
        top: 200,
    }
}));

export type BrandedCardProps = {
    isLoading?: boolean,
    loaderText?: string,
    maxWidth?: number,
    minHeight?: number,
    showLogo?: boolean,
    children: ReactNode[]
}

const defaultProps: Partial<BrandedCardProps> = {
    isLoading: false,
    maxWidth: 450,
    minHeight: 500,
}

const BrandedCard = (props: BrandedCardProps) => {
    const actualProps = {...defaultProps, ...props};
    const { showLogo, isLoading } = actualProps;
    const classes = useStyles(actualProps);

    const brand = useBrand();

    const header = actualProps.children.find((child: any) => child.type.displayName === "BrandedCardHeader");
    const content = actualProps.children.find((child: any) => child.type.displayName === "BrandedCardContent");
    const actions = actualProps.children.find((child: any) => child.type.displayName === "BrandedCardActions");

    const logo = showLogo ? <BrandedCardLogo /> : '';

    const spinnerOptions = { ...SpinnerProperties, position: undefined };

    const loaderText = isLoading ? <TypographyI18N className={classes.loaderText}>{actualProps.loaderText}</TypographyI18N> : null;

    return (
        <Grid container justify="center" alignItems="stretch" direction="column" className="background">    
            <Grid item xs={12}>
                <Grid container justify="center" direction="row">
                    <Grid item xs={12}>&nbsp;</Grid>
                    <Grid item className={classes.middleContainer} container xs={11}>
                        <Grid container className={classes.innerContainer} item xs={12} direction="row">
                            <BrandedCardHelmet />
                            {logo}  
                            {header}
                            <Loader loaded={!isLoading} options={spinnerOptions}>
                                {content}
                                {actions}
                            </Loader>
                            {loaderText}
                        </Grid>
                    </Grid>
                    <Grid container justify="center" item xs={11}>
                        <LegalLinks brandInfo={brand} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BrandedCard;
