import React from 'react';
import Grid from '@material-ui/core/Grid';

import firstNameControl from '../../controls/FirstNameControl';
import lastNameControl from '../../controls/LastNameControl';
import dateOfBirthControl from '../../controls/DateOfBirthControl';
import zipCodeControl from '../../controls/ZipCodeControl';

export type UsernameRecoveryFormProps = {
  control: any,
  errors: any
}

function UsernameRecoveryForm(props: UsernameRecoveryFormProps): JSX.Element {
    return (
        <Grid container spacing={9}>
            <Grid container justify="center" item xs={12}>
                <Grid item xs={11} sm={8}>
                </Grid>
                <Grid item xs={11} sm={8}>
                    {firstNameControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={11} sm={8}>
                </Grid>
                <Grid item xs={11} sm={8}>
                    {lastNameControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={11} sm={8}>
                </Grid>
                <Grid item xs={11} sm={8}>
                    {dateOfBirthControl(props.control, props.errors, undefined)}
                </Grid>
                <Grid item xs={11} sm={8}>
                </Grid>
                <Grid item xs={11} sm={8}>
                    {zipCodeControl(props.control, props.errors, true)}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default UsernameRecoveryForm;
