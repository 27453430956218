import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

const numbersOnly = (data: any) => {
    data.target.value = data.target.value.replace(/[^0-9]/g, '')
};

export default function last4SsnControl(control: any, errors: any, required: boolean, shrink?: boolean): JSX.Element {
    const {t} = useTranslation();
    return (
        <Controller
            as={TextField}
            control={control}
            defaultValue=""
            name="ssn"
            rules={{
                required,
                minLength: 4,
                maxLength: 4
            }}
            fullWidth
            id="ssn"
            type="password"
            autoComplete="off"
            inputProps={{ maxLength: 4 }}
            onInput={(e: React.FormEvent<HTMLDivElement>) => numbersOnly(e) }
            label={(errors.ssn?.type === "required" && <div>{t("SSN, last 4 digits " + (!required ? " (optional)" : ""))}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.ssn?.type === "minLength" && <div>{t("SSN, last 4 digits " + (!required ? " (optional)" : ""))}<div className="errorLabelAsterisk">*</div></div>) ||
            (errors.ssn?.type === "maxLength" && <div>{t("SSN, last 4 digits " + (!required ? " (optional)" : ""))}<div className="errorLabelAsterisk">*</div></div>) ||
            t("SSN, last 4 digits" + (!required ? " (optional)" : ""))}
            error={errors.ssn?.type === "required" || errors.ssn?.type === "minLength" || errors.ssn?.type === "maxLength"}
            helperText=
            {(errors.ssn?.type === "required" && t("SSN is required")) ||
            ((errors.ssn?.type === "validate" || errors.ssn?.type === "minLength"
            || errors.ssn?.type === "maxLength") && t("SSN is incorrect")) ||
            (true && " ")}
            InputLabelProps={{
                shrink: shrink,
                error: false,
            }}
        />);
}
