import { ApiRequestLogger } from "../logger/apiRequestLogger";

export function getMobileOperatingSystem() {
    const log = new ApiRequestLogger({ eventSource: 'AppPromotionPage' });
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    else if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }
    log.warn(`getMobileOperatingSystem(): UserAgent - ${userAgent} returned unknown OS.`);
    return "unknown";
}

export function getDeviceType(): string {
    let deviceType = "Desktop";
    if (/Mobi|Mobile|Android|iPhone|iPad|iPod|Windows Phone|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        deviceType = "Mobile";
    }
    return deviceType;
}
