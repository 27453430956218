import React from 'react';
import { Controller } from "react-hook-form";
import { TextField, Grid } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

export const TextMaskCustom = (props: any) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            mask={[/(a|\d)/, /(a|\d)/, /(a|\d)/, /(a|\d)/, /(a|\d)/, /(a|\d)/]}
            placeholderChar={'\u2000'}
            keepCharPositions
            guide={false}
            showMask
        />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default function verificationCodeControl(control: any, errors: any, translation: any, shrink?: boolean, invalid?: boolean) {
    return (
        <>
            <Grid item xs={12}>
                <Controller
                    as={TextField}
                    control={control}
                    defaultValue=""
                    name="verificationCode"
                    rules={{
                        required: true,
                        minLength: 6,
                        maxLength: 6
                    }}
                    fullWidth
                    id="verificationCode"
                    label={translation("Verification code")}
                    error={errors.verificationCode?.type === "required" || invalid}
                    helperText={(errors.verificationCode?.type === "required" && translation("Verification code is required")) ||
                                (invalid && translation("Invalid verification code")) ||
                                (true && " ")}
                    placeholder=""
                    InputProps={{
                        inputComponent: TextMaskCustom,
                    }}
                    InputLabelProps={{
                        shrink: shrink,
                        error: false,
                    }}
                />
            </Grid>
        </>
        );
}
