import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

export const TextMaskCustom = (props: any) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            keepCharPositions
            guide={false}
            showMask
        />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default function mobilePhoneControl(control: any, errors: any, labelOverride: string, shrink?: boolean, phoneNumber?: string) {
    const {t} = useTranslation();
    if (phoneNumber) {
        return (
            <TextField
                value={phoneNumber}
                disabled
                placeholder={phoneNumber}
                name="cellPhoneNumber"
                fullWidth
                id="cellPhoneNumber"
                label={(errors.cellPhoneNumber?.type === "required" && <div>{t(labelOverride + " ")}<div className="errorLabelAsterisk">*</div></div>) ||
                (errors.cellPhoneNumber?.type === "pattern" && <div>{t(labelOverride + " ")}<div className="errorLabelAsterisk">*</div></div>) ||
                t(labelOverride)}
                error={errors.cellPhoneNumber?.type === "required" || errors.cellPhoneNumber?.type === "pattern"}
                helperText=
                    {(errors.cellPhoneNumber?.type === "required" && t("Cell phone number is required")) ||
                    (errors.cellPhoneNumber?.type === "pattern" && t("Must be a valid phone number 555-867-5309")) ||
                    (true && " ")}
                InputLabelProps={{
                    shrink: true,
                    error: false,
                }}
                InputProps={{
                    inputComponent: TextMaskCustom,
                }}
                autoComplete="tel"
            />);

    } else {
        return (
            <Controller
                as={TextField}
                control={control}
                defaultValue=""
                name="cellPhoneNumber"
                rules={{
                    required: true,
                    pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
                }}
                // variant={oneSiteStyling ? "outlined" : "standard"}
                fullWidth
                id="cellPhoneNumber"
                label={(errors.cellPhoneNumber?.type === "required" && <div>{t(labelOverride + " ")}<div className="errorLabelAsterisk">*</div></div>) ||
                (errors.cellPhoneNumber?.type === "pattern" && <div>{t(labelOverride + " ")}<div className="errorLabelAsterisk">*</div></div>) ||
                t(labelOverride)}
                error={errors.cellPhoneNumber?.type === "required" || errors.cellPhoneNumber?.type === "pattern"}
                helperText=
                    {(errors.cellPhoneNumber?.type === "required" && t("Cell phone number is required")) ||
                    (errors.cellPhoneNumber?.type === "pattern" && t("Must be a valid phone number 555-867-5309")) ||
                    (true && " ")}
                InputLabelProps={{
                    shrink: shrink,
                    error: false,
                }}
                InputProps={{
                    inputComponent: TextMaskCustom,
                }}
                autoComplete="tel"
                type='tel'
            />);

    }
}
