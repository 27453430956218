import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import mobilePhoneControl from '../../controls/MobilePhoneControl';
import dateOfBirthControl from '../../controls/DateOfBirthControl';
import zipCodeControl from '../../controls/ZipCodeControl';
import last4SsnControl from '../../controls/Last4SsnControl';
import genderControl from '../../controls/GenderControl';
import streetAddressControl from '../../controls/StreetAddressControl';
import cityControl from '../../controls/CityControl';
import stateControl from '../../controls/StateControl';
import firstNameControl from "../../controls/FirstNameControl";
import lastNameControl from "../../controls/LastNameControl";

export type CollectDemographicsFormProps = {
    control: any,
    errors: any,
    infoComponent: string,
}

function CollectDemographicsForm(props: CollectDemographicsFormProps) {
    const {t} = useTranslation();
    const infoComponent = (
        <>
            {t(props.infoComponent)}
        </>
    );

    return (
        <Grid container spacing={2} direction="row" justify="space-evenly">
            <Grid container justify="center" alignContent="flex-end" alignItems="flex-end">
                <Grid item xs={9} sm={3}>
                    {firstNameControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={9} sm={3}>
                    {lastNameControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={9} sm={3}>
                    {genderControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={9} sm={3}>
                    {dateOfBirthControl(props.control, props.errors, true)}
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={9} sm={3}>
                    {mobilePhoneControl(props.control, props.errors, "Phone number")}
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={9} sm={3}>
                    {last4SsnControl(props.control, props.errors, false, true)}
                </Grid>
                <Grid item xs={9} sm={11}>
                    {streetAddressControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={9} sm={3}>
                    {cityControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={9} sm={3}>
                    {stateControl(props.control, props.errors)}
                </Grid>
                <Grid item xs={false} sm={1} />
                <Grid item xs={9} sm={3}>
                    {zipCodeControl(props.control, props.errors, false)}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
            <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={9} className="noteText">{infoComponent}</Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    );
}

export default CollectDemographicsForm;
