import React, { ReactNode } from 'react';
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Variant } from '@material-ui/core/styles/createTypography';

type TypographyI18NProps = {
    className?: string,
    variant?: Variant,
    children: ReactNode
}

const TypographyI18N = (props: TypographyI18NProps) => {
    const { className, variant = 'body1' } = props;
    const {t} = useTranslation();
    return (
        <Typography className={className} variant={variant}>
            {t(props.children)}
        </Typography>
    );
}

TypographyI18N.displayName = 'TypographyI18N';
export default TypographyI18N;
