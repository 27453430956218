


import { Grid } from '@material-ui/core';
import React from 'react';
import { useBrand } from '../../../hooks/useBrand';
import Logo from '../../elements/Logo';

const BrandedCardLogo = () => {
    const { logoSize, logoUri, logoAltText } = useBrand();
    return (
        <Grid item justify="center" xs={12}>
            <Logo size={logoSize ? logoSize : 200} imageUri={logoUri} altText={logoAltText} />
        </Grid>
    );
}
BrandedCardLogo.displayName = 'BrandedCardLogo';
export default BrandedCardLogo;
