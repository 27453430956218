import React, { useContext } from "react"
import { ClientConfigContext } from "../../../clientContext";
import { BrandProps, ClientConfigType } from "../../../../types/clientConfigTypes"

export const AppPromImage = ({ brandInfo }: BrandProps) => {
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const cdnPath = clientConfiguration.cdnPath;
    
    return (
        <div className="promotionImageContainer">
            <div className="promotionColorBlock" style={{ backgroundColor: brandInfo.promotion?.brandColor }}>
            </div>
            <div className="promotionImage">
                <img src={`${cdnPath}${brandInfo.promotion?.appImage}`} />
            </div>
        </div>
    )
}