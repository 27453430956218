import {Card, Grid, makeStyles} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import React, {useContext, useEffect} from 'react';
import {ClientConfigType} from "../../../types/clientConfigTypes";
import {ClientConfigContext} from "../../clientContext";
import {ApiRequestLogger} from "../../logger/apiRequestLogger";
import {useMsal} from "@azure/msal-react";
import VerifyContactCustomerService from "../containers/VerifyContactCustomerService";

const useStyles = makeStyles(() => ({
    card: {
        padding: '18px',
    },
    header: {
        margin: '10px',
        marginBottom: '20px',
    },
    link: {
        margin: '10px',
    }
}));

/**
 * [SPI-3848] The debug menu is only shown on the non-prod environments.
 * On Prod, this will become the error page (Contact Support).
 * @param brandInfo
 * @constructor
 */
export default function DebugMenuPage() {
    const history = useHistory();
    const logger = new ApiRequestLogger({eventSource: 'DebugMenuPage'});
    const {instance} = useMsal();
    const { tier, version } = useContext<ClientConfigType>(ClientConfigContext);
    const classes = useStyles();

    useEffect(() => {
        logger.debug(`DebugMenuPage Page History: ${JSON.stringify(history)}`);
    }, []);

    // Only allow the debug menu to be shown on dev and local environments
    const debugTiers = ["dev", "local"];
    if (!debugTiers.includes(tier)) {
        return <VerifyContactCustomerService />;
    }

    return (
        <Grid container justify="center">
            <Card className={classes.card}>

                <Grid item xs={12} className={classes.header}>
                    Welcome to Patient Identity Portal <br/>
                    <b>Version: {version}</b>
                </Grid>

                <Grid item className={classes.link}>
                    <Link to="/register?mobile=true&redirect_url=https%3A%2F%2Fwww.google.com%2F#">
                        Register Flow from Mobile
                    </Link>
                </Grid>

                <Grid item className={classes.link}>
                    <Link to="/register?instAct=true&brand=">
                        Register Flow (Unbranded from Instant Activation)
                    </Link>
                </Grid>

                <Grid item className={classes.link}>
                    <Link to="/autoinstantactivation">
                        Auto Instant Activation
                    </Link>
                </Grid>

                <Grid item className={classes.link}>
                    <Link to="/autoinstantactivation/landing">
                        Auto Instant Activation Landing Page
                    </Link>
                </Grid>

                <Grid item className={classes.link}>
                    <Link to="/username-recovery">
                        Username Recovery
                    </Link>
                </Grid>

                <Grid item className={classes.link}>
                    <Link to="/change-password?redirect_uri=https%3A%2F%2Fwww.google.com%2F#">
                        Change password
                    </Link>
                </Grid>

                <Grid item className={classes.link}>
                    <Link to="/mfa-setup">
                        Mfa By Email
                    </Link>
                </Grid>

                <Grid item className={classes.link}>
                    <button onClick={() => instance.logout()}>Sign Out</button>
                </Grid>
            </Card>
        </Grid>
    );
}
